import {connect} from 'react-redux';

import {type AppState} from 'store/interface';

import {type AudioFileWithLength, type PlayStatusV2} from '../../../interface';
import AudioWidgetSlider from '../../views/components/AudioWidgetSlider/AudioWidgetSlider';

interface StateProps {
  activeAudioFile?: AudioFileWithLength;
  isMobile?: boolean;
  playStatus?: PlayStatusV2;
  timestamp?: number;
}

export interface Props extends StateProps {
  initTimestamp: number;
  changePlayStatus: (playStatus?: PlayStatusV2, forceRestart?: true) => void;
  changeTimestamp: (timestamp: number) => void;
}

type StateToProps = (state: AppState) => StateProps;

const mapStateToProps: StateToProps = ({layout, media}) =>
  media.audio
    ? {
        activeAudioFile: media.audio.audioFile as AudioFileWithLength,
        isMobile: layout.isMobile,
        playStatus: media.audio.playStatus,
        timestamp: media.audio.timestamp
      }
    : {};

export default connect<StateProps>(mapStateToProps)(AudioWidgetSlider);
