import {type RenderAttributes} from '@englex/slate-react';
import classNames from 'classnames';
import React, {memo} from 'react';

import {type StyledListStyles} from 'store/exercise/player/widgets/List/StyledList/interface';

import './HorizontalItem.scss';

interface Props {
  attributes?: RenderAttributes;
  id: string;
  styleName: StyledListStyles;
  isActive: boolean;
  children: React.ReactNode;
  withNumbering?: boolean;
  index?: number;
  onClick?: () => void;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
}

export const HorizontalItem: React.FC<Props> = memo(
  ({attributes, id, isActive, styleName, withNumbering, index, children, onClick, onPaste}) => {
    return (
      <div
        {...attributes}
        id={id}
        className={classNames('horizontal-styled-list-item', styleName, {
          'is-active': isActive,
          'with-numbering': withNumbering
        })}
        onClick={onClick}
        onPaste={onPaste}
      >
        {withNumbering && typeof index !== 'undefined' && (
          <span className="horizontal-styled-list-item__number">{index + 1}</span>
        )}
        {children}
      </div>
    );
  }
);
