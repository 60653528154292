import React from 'react';
import {FocusableContext} from '@englex/paint-react';
import {injectIntl, type WrappedComponentProps} from 'react-intl';
import classNames from 'classnames';
import {connect} from 'react-redux';
import screenfull from 'screenfull';

import {changePlaybackRate} from 'store/media/video/actions';
import {type AppState, type Role} from 'store/interface';
import Throttle from 'common/Throttle';
import {batchMedia} from 'common/action';
import Loader from 'components/Loader';
import {
  LOADING,
  type MediaContext,
  MediaContextAction,
  type MediaType,
  PAUSE,
  PLAYING,
  type PlayStatusV2
} from 'components/media/interface';
import VideoLoaderView from 'components/media/VideoPlayer/VideoLoaderView';
import {isSafari} from 'helpers/browser';
import {openingUrlForStudentTimeout} from 'config/static';

import VideoIos from './VideoIos';
import Video from './Video';
import {Direction, VideoRewind} from './Rewind';
import {errorMessages} from '../../../_common/errorMessages';

interface StateProps {
  batchedMedia?: string;
  batchedMediaContext?: MediaContext;
  duration?: number;
  playbackRate?: number;
  posterUrls?: string[];
  url?: string;
}

interface DispatchProps {
  changePlaybackRate(playbackRate: number): void;
  batchMedia(): void;
}

interface OwnProps {
  id: string;
  videoId: number;
  widgetId: string;
  isActive: boolean;
  activePlayerId?: string;
  isMobile?: boolean;
  preview?: boolean;
  role?: Role;
  volume: number;
  requestError?: boolean;
  reload(): void;
  changeVolume(volume: number): void;
  setDuration(duration: number): void;
  activatePlayer(playerId: string): void;
  deactivatePlayer(playerId: string): void;
  actionTogether(data: {id: string; mediaType: MediaType; mediaContext: MediaContext}): void;
  showActionTogetherError(errorMessage: string): void;
}

interface Props extends OwnProps, StateProps, DispatchProps, WrappedComponentProps {}

interface State {
  controlFocused: boolean;
  forceRestart?: true;
  hideControlsTimeout?: NodeJS.Timeout;
  isFullscreen: boolean;
  playStatus?: PlayStatusV2;
  volumePoppedUp: boolean;
  playbackRatePoppedUp?: boolean;
  isTogether: boolean;
  currentTime: number;
}

class VideoPlayer extends React.Component<Props, State> {
  static contextType = FocusableContext;

  public state: State = {
    controlFocused: false,
    isFullscreen: false,
    volumePoppedUp: false,
    playbackRatePoppedUp: false,
    isTogether: false,
    currentTime: 0
  };

  private player: React.RefObject<HTMLVideoElement> = React.createRef();
  private static readonly showHideTimeout = 3000;
  private component: HTMLDivElement | null;
  private readonly screenfull = screenfull;
  private readonly throttle = new Throttle();
  private actionTogetherTimeout: null | NodeJS.Timeout = null;

  private static get isIos() {
    if (!isSafari()) return false;
    return 'ontouchstart' in window;
  }

  private get mediaContext(): MediaContext | undefined {
    return this.props.widgetId
      ? {widgetId: this.props.widgetId, mediaId: this.props.videoId}
      : undefined;
  }

  private toggleIsTogether = () => {
    this.setState(
      prevState => ({...prevState, isTogether: !prevState.isTogether}),
      () => {
        if (this.state.isTogether) {
          this.actionTogether(MediaContextAction.Play, {
            timestamp: this.state.currentTime,
            playbackRate: this.props.playbackRate
          });
        }
      }
    );
  };

  private resetIsTogether = () => {
    this.setState(prevState => ({...prevState, isTogether: false}));
  };

  private get shouldShowControls() {
    if (VideoPlayer.isIos) {
      return false;
    }
    const {playStatus, hideControlsTimeout, isFullscreen, volumePoppedUp, playbackRatePoppedUp} =
      this.state;
    const {isMobile} = this.props;
    if (isMobile && !isFullscreen) {
      return true;
    }
    return !!(
      hideControlsTimeout ||
      !playStatus ||
      playStatus === PAUSE ||
      volumePoppedUp ||
      playbackRatePoppedUp
    );
  }

  private get classes() {
    const {isFullscreen} = this.state;
    const {isMobile} = this.props;
    return classNames('enlx-video-player-wrapper', {
      fullscreen: isFullscreen && !VideoPlayer.isIos,
      ios: VideoPlayer.isIos,
      'is-mobile': isMobile,
      'show-controls': this.shouldShowControls
    });
  }

  public componentDidUpdate(prevProps: Props) {
    const {
      id,
      activePlayerId,
      activatePlayer,
      batchMedia,
      batchedMedia,
      batchedMediaContext,
      role,
      changePlaybackRate
    } = this.props;

    if (prevProps.batchedMedia && prevProps.batchedMedia !== batchedMedia) {
      this.clearActionTogetherTimeout();
    }

    if (
      batchedMedia &&
      batchedMedia !== id &&
      role === 'teacher' &&
      (this.state.playStatus === PLAYING || this.state.playStatus === PAUSE)
    ) {
      this.stopVideo();
    }

    if (batchedMedia === id) {
      switch (batchedMediaContext?.action) {
        case MediaContextAction.Play:
          if (this.state.playStatus !== PLAYING) {
            activatePlayer(id);
            this.setState({playStatus: PLAYING});
          }

          if (role === 'student') {
            this.updateCurrentTime(batchedMediaContext.timestamp!);

            if (batchedMediaContext.playbackRate) {
              changePlaybackRate(batchedMediaContext.playbackRate);
            }
          }
          break;

        case MediaContextAction.Pause:
          if (activePlayerId === undefined) {
            activatePlayer(id);
          }

          if (this.state.playStatus !== PAUSE) {
            this.setState({playStatus: PAUSE});
          }
          break;

        case MediaContextAction.Stop:
          this.stopVideo();
          break;

        case MediaContextAction.ChangeRate:
          changePlaybackRate(batchedMediaContext.playbackRate!);
          break;

        case MediaContextAction.ChangeTimestamp:
          this.setState({playStatus: batchedMediaContext?.playStatus});
          this.updateCurrentTime(batchedMediaContext.timestamp!);
          break;
      }

      batchMedia();

      if (role === 'student' && batchedMediaContext?.action !== MediaContextAction.Stop)
        document.getElementById(id)?.scrollIntoView({block: 'start', behavior: 'smooth'});
    }
  }

  public componentWillUnmount() {
    const {hideControlsTimeout} = this.state;
    if (hideControlsTimeout) clearTimeout(hideControlsTimeout);
  }

  public render() {
    const {id} = this.props;
    const {VideoBlock} = this;
    return (
      <figure ref={this.componentRef} className={this.classes} id={id}>
        <VideoBlock />
        {this.state.playStatus === LOADING && (
          <div
            className="loader-wrapper"
            onMouseMove={VideoPlayer.isIos ? undefined : this.showControls}
          >
            <Loader />
          </div>
        )}
      </figure>
    );
  }

  private VideoBlock = () => {
    const {activatePlayer, deactivatePlayer, isActive, id, posterUrls, reload, requestError, url} =
      this.props;
    if (!url) {
      return <VideoLoaderView reload={reload} hasError={requestError} />;
    }
    if (VideoPlayer.isIos) {
      return (
        <VideoIos
          id={id}
          activatePlayer={activatePlayer}
          deactivatePlayer={deactivatePlayer}
          isActive={isActive}
          posterUrls={posterUrls}
          url={url}
        />
      );
    }
    const {
      activePlayerId,
      changeVolume,
      duration,
      isMobile,
      preview,
      role,
      setDuration,
      volume,
      playbackRate
    } = this.props;
    const {currentTime, isFullscreen, forceRestart, playStatus, isTogether} = this.state;
    const isRewindActive = isActive && !!playStatus;
    return (
      <>
        <Video
          id={id}
          player={this.player}
          isTogether={isTogether}
          isActive={isActive}
          volume={volume}
          currentTime={currentTime}
          reload={reload}
          changeVolume={changeVolume}
          setDuration={setDuration}
          activatePlayer={activatePlayer}
          deactivatePlayer={deactivatePlayer}
          url={url!}
          posterUrls={posterUrls}
          duration={duration}
          isFullscreen={isFullscreen}
          playStatus={playStatus}
          playbackRate={playbackRate}
          preview={preview}
          isMobile={isMobile}
          role={role}
          activePlayerId={activePlayerId}
          forceRestart={forceRestart}
          stop={this.stop}
          changePlaybackRate={this.changePlaybackRate}
          toggleControlState={this.toggleControlState}
          toggleFullscreen={this.toggleFullscreen}
          showControls={this.showControls}
          shouldShowControls={this.shouldShowControls}
          onVolumePopup={this.onVolumePopup}
          onPlaybackRatePopup={this.onPlaybackRatePopup}
          setStatus={this.setStatus}
          triggerPlayPause={this.togglePlayPause}
          toggleIsTogether={this.toggleIsTogether}
          resetIsTogether={this.resetIsTogether}
          clearForceRestart={this.clearForceRestart}
          setCurrentTime={this.setCurrentTime}
          onChangeCurrentTime={this.onChangeCurrentTime}
        >
          <VideoRewind
            isActive={isRewindActive}
            onChange={this.onForwardOrBackward}
            onClick={this.onRewindClick}
          />
        </Video>
      </>
    );
  };

  private onRewindClick = () => {
    this.showControls();
  };

  private onForwardOrBackward = (direction: Direction, offset: number) => {
    this.showControls();

    if (!this.state.playStatus) {
      this.setStatus(PAUSE);
    }

    if (direction === Direction.Backward) {
      this.onChangeCurrentTime(Math.max(this.state.currentTime - offset, 0));
    }

    if (direction === Direction.Forward) {
      this.onChangeCurrentTime(this.state.currentTime + offset);
    }
  };

  private clearForceRestart = () => this.setState({forceRestart: undefined});

  private componentRef = (el: HTMLDivElement | null) => (this.component = el);

  private setStatus = (playStatus?: PlayStatusV2) => this.setState({playStatus});

  private setCurrentTime = (currentTime: number) => this.setState({currentTime});

  private showControls = () => {
    if (!this.shouldShowControls) {
      this.throttle.throttleAction(() => {
        const {hideControlsTimeout: hct} = this.state;
        if (hct) clearTimeout(hct);
        this.setState({
          hideControlsTimeout: setTimeout(() => {
            this.setState({hideControlsTimeout: undefined});
          }, VideoPlayer.showHideTimeout)
        });
      });
    }
  };

  private toggleControlState = (newState: boolean) => {
    this.setState({controlFocused: newState});
  };

  private toggleFullscreen = async () => {
    if (VideoPlayer.isIos) {
      return;
    }
    const {isFullscreen} = this.state;
    if (isFullscreen) {
      await this.screenfull.exit();
      return;
    }
    if (this.component) {
      this.screenfull.request(this.component).then(() => {
        if (this.component) {
          this.screenfull.on('change', this.fullscreenChangeListener);
        }
        this.setState({isFullscreen: true});

        document.addEventListener('keydown', this.changePlayStatusOnKeyDown);
      });
    }
  };

  private togglePlayPause = () => {
    const {playStatus} = this.state;

    if (this.state.isTogether) {
      const action =
        playStatus === PLAYING || playStatus === LOADING
          ? MediaContextAction.Pause
          : MediaContextAction.Play;

      return this.actionTogether(action, {
        timestamp: this.props.activePlayerId === this.props.id ? this.state.currentTime : 0,
        playbackRate: this.props.playbackRate
      });
    }

    this.setStatus(playStatus === PLAYING || playStatus === LOADING ? PAUSE : PLAYING);
  };

  private stop = () => {
    if (!this.state.playStatus) return;

    if (this.state.isTogether) {
      return this.actionTogether(MediaContextAction.Stop);
    }

    this.stopVideo();
  };

  private stopVideo() {
    if (this.player.current) {
      this.player.current.pause();
      this.player.current.currentTime = 0;
      this.setState({playStatus: undefined});
    }
  }

  private changePlaybackRate = (playbackRate: number) => {
    if (this.state.isTogether) {
      return this.actionTogether(MediaContextAction.ChangeRate, {playbackRate});
    }

    this.props.changePlaybackRate(playbackRate);
  };

  private onChangeCurrentTime = (timestamp: number) => {
    const {isTogether, playStatus} = this.state;

    if (isTogether) {
      return this.actionTogether(MediaContextAction.ChangeTimestamp, {timestamp, playStatus});
    }

    this.updateCurrentTime(timestamp);
  };

  private updateCurrentTime(currentTime: number) {
    if (this.player.current) {
      this.setState({currentTime});
      this.player.current.currentTime = currentTime;
    }
  }
  private changePlayStatusOnKeyDown: EventListenerObject = {
    handleEvent: (e: KeyboardEvent) => {
      if (e.code === 'Space' && !this.state.controlFocused) {
        this.togglePlayPause();
      }
      if (e.code === 'KeyK') {
        this.togglePlayPause();
      }
    }
  };

  private fullscreenChangeListener = () => {
    if (this.screenfull.isFullscreen) {
      return;
    }
    this.setState({isFullscreen: false});
    if (this.component) {
      this.screenfull.off('change', this.fullscreenChangeListener);
    }
    document.removeEventListener('keydown', this.changePlayStatusOnKeyDown);
  };

  private onVolumePopup = (volumePoppedUp: boolean) => this.setState({volumePoppedUp});

  private onPlaybackRatePopup = (playbackRatePoppedUp?: boolean) =>
    this.setState({playbackRatePoppedUp});

  private actionTogether(action: MediaContextAction, actionData?: Partial<MediaContext>) {
    if (this.actionTogetherTimeout) return;

    const errorMessage = this.props.intl.formatMessage(errorMessages[action]);

    this.actionTogetherTimeout = setTimeout(
      () => this.onErrorTogetherAction(errorMessage),
      openingUrlForStudentTimeout
    );

    this.props.actionTogether({
      id: this.props.id,
      mediaType: 'video',
      mediaContext: {...this.mediaContext!, ...actionData, action}
    });
  }

  private onErrorTogetherAction = (errorMessage: string) => {
    this.clearActionTogetherTimeout();
    this.props.showActionTogetherError(errorMessage);
  };

  private clearActionTogetherTimeout() {
    if (this.actionTogetherTimeout) {
      clearTimeout(this.actionTogetherTimeout);
      this.actionTogetherTimeout = null;
    }
  }
}

const mapStateToProps = (state: AppState, {id}: OwnProps): StateProps => {
  const player = state.media.video.players.find(p => p.id === id);
  return {
    batchedMedia: state.classroom?.batchedMedia,
    batchedMediaContext: state.classroom?.batchedMediaContext,
    duration: player?.duration,
    url: player?.url,
    posterUrls: player?.posterUrls,
    playbackRate: state.media.video.playbackRate
  };
};

export default connect(mapStateToProps, {batchMedia, changePlaybackRate})(injectIntl(VideoPlayer));
