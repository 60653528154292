import {type Action} from 'redux';
import {type ThunkAction} from 'redux-thunk';

import {type AppState} from 'store/interface';
import {publishOpenUrl, setOpeningUrlForPartner} from 'common/action';
import {changeAudio} from 'store/media/audio/actions';

import {type AudioFile, LOADING, type MediaContext, type MediaType} from '../../../interface';

export function actionTogether(data: {
  courseId: number;
  url: string;
  id: string;
  mediaType: MediaType;
  mediaContext: MediaContext;
}): ThunkAction<unknown, AppState, never, Action> {
  return (dispatch, getState) => {
    const partnerSession = getState().rtc.partnerSessionId;

    if (partnerSession) {
      dispatch(publishOpenUrl({...data, partnerSession}));
      dispatch(setOpeningUrlForPartner());
    }
  };
}

export function changeActiveAudio(
  audioFile: AudioFile,
  context?: MediaContext
): ThunkAction<Promise<boolean>, AppState, never, Action> {
  return (dispatch, getState) => {
    dispatch(changeAudio(audioFile, context));

    return new Promise(resolve => {
      const interval = setInterval(() => {
        const state = getState();

        if (state.media.audio?.playStatus !== LOADING) {
          clearInterval(interval);
          resolve(true);
        }
      }, 100);
    });
  };
}
