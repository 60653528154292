import React from 'react';

import {emitter} from 'services/event-emitter';
import {formatSecondsToString} from 'services/common-methods';

interface Props {
  event: string;
  currentTime: number;
}

interface State {
  currentTime: string;
}

export default class TimeDisplay extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      currentTime: formatSecondsToString(this.props.currentTime)
    };
  }

  public componentDidMount() {
    emitter.addListener(this.props.event, this.receiveTime);
  }

  public componentWillUnmount() {
    emitter.removeListener(this.props.event, this.receiveTime);
  }

  public render() {
    return <span>{`${this.state.currentTime} / `}</span>;
  }

  private receiveTime = (time: number) => {
    this.setState({currentTime: formatSecondsToString(time)});
  };
}
