import React, {Component, type SyntheticEvent} from 'react';
import {captureMessage, withScope} from '@sentry/react';
import {injectIntl, type WrappedComponentProps} from 'react-intl';
import classNames from 'classnames';
import RetinaImage from '@englex/react-retina-image';

import {VideoSourceType} from 'components/media/interface';
import {videoMessages} from 'components/media/VideoPlayer/messages';
import * as toastr from 'components/toastr';

import {ReactComponent as SVG} from './play.svg';

interface Props extends WrappedComponentProps {
  id: string;
  isActive: boolean;
  posterUrls?: string[];
  url: string;
  activatePlayer(playerId: string): void;
  deactivatePlayer(playerId: string): void;
}

interface State {
  isPlaying: boolean;
}

class VideoIos extends Component<Props, State> {
  public state: State = {isPlaying: false};
  private player: HTMLVideoElement | null;

  public componentDidUpdate(prevProps: Props, prevState: State) {
    this.shouldDispatch(prevProps, prevState);
  }

  public render() {
    const {url, posterUrls} = this.props;
    const {isPlaying} = this.state;
    return (
      <div className="positioner ios">
        <video
          className={classNames('enlx-video-player', {invisible: !isPlaying && posterUrls})}
          src={url}
          ref={this.playerRef}
          // listeners
          onEnded={this.stop}
          onError={this.onError}
          controls={true}
        />
        {!isPlaying && (
          <>
            {posterUrls ? (
              <RetinaImage src={posterUrls} className="poster" />
            ) : (
              <div className="poster fallback" />
            )}
            <button onClick={this.play} className="poster btn">
              <SVG />
            </button>
          </>
        )}
      </div>
    );
  }

  private playerRef = (el: HTMLVideoElement | null) => (this.player = el);

  private onError = (e: SyntheticEvent) => {
    this.stop();
    toastr.error('', this.props.intl.formatMessage(videoMessages.PlaybackError));
    withScope(scope => {
      scope.setExtras({
        event: e,
        video: {
          id: this.props.id,
          source: VideoSourceType.SELECTEL,
          url: this.props.url
        }
      });
      captureMessage('Selectel Player onError event', 'warning');
    });
  };

  private play = () => {
    if (!this.state.isPlaying && this.player) {
      this.player.play();
      this.setState({isPlaying: true});
    }
  };

  private stop = () => {
    if (this.player) {
      const {deactivatePlayer, id} = this.props;
      this.player.pause();
      this.player.currentTime = 0;
      this.setState({isPlaying: false});
      deactivatePlayer(id);
    }
  };

  private shouldDispatch({isActive: wasActive}: Props, {isPlaying: wasPlaying}: State) {
    const {isActive, activatePlayer, id} = this.props;
    const {isPlaying} = this.state;
    if (!wasPlaying && isPlaying) activatePlayer(id);
    if (wasActive && !isActive) this.stop();
    if (wasPlaying && !isPlaying) this.stop();
  }
}

export default injectIntl(VideoIos);
