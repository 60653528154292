import React from 'react';
import classNames from 'classnames';

import {type GapFillType} from '../../../../Slate/interface';
import Dot from '../../../components/Dot';
import './GapChecked.scss';

export interface GapCheckedProps {
  id?: string;
  className?: string;
  gap: GapFillType | string;
  value: string;
  title?: string;
  empty?: boolean;
  dirty?: boolean;
  correct?: boolean;
  closed?: boolean;
  example?: boolean;
  dot?: boolean;
  missing?: boolean;
  neverChangeCursor?: boolean;
  isLarge?: true;
  childPullRight?: true;
  placeholder?: string;
  flexHandle?: true;
  editable?: true;
  orphan?: boolean;
  isFreeChoice?: boolean;
  multipleAnswers?: boolean;
}

class GapChecked extends React.PureComponent<GapCheckedProps> {
  public render() {
    const {
      id,
      gap,
      dirty,
      correct,
      empty,
      closed,
      dot,
      value,
      title,
      children,
      example,
      missing,
      neverChangeCursor,
      isLarge,
      childPullRight,
      placeholder,
      flexHandle,
      isFreeChoice,
      orphan,
      multipleAnswers,
      ...rest
    } = this.props;
    const className = classNames(
      'x-gap-core',
      'x-gap-checked',
      gap,
      this.props.className ? {[this.props.className]: this.props.className} : {},
      {
        dirty,
        correct,
        empty,
        example,
        closed,
        missing,
        flexHandle,
        orphan,
        large: isLarge,
        'free-choice': isFreeChoice,
        'multiple-answers': multipleAnswers,
        'default-cursor': neverChangeCursor,
        'with-placeholder': !dirty && placeholder && !closed
      }
    );

    const elementTitle = typeof title === 'string' ? title : value;

    return (
      // rest props required for wrapping the component by rc-tooltip {@see:https://github.com/react-component/tooltip/issues/51}
      <span id={id} {...rest} className={className}>
        {childPullRight ? null : children}
        <span className="value" title={elementTitle}>
          {!dirty && placeholder && !closed ? placeholder : value}
        </span>
        {childPullRight && children}
        <Dot show={dot} />
      </span>
    );
  }
}

export default GapChecked;
