import React from 'react';

import {RewindBubble} from './RewindBubble';
import {type Direction} from './types';

interface Props {
  direction: Direction;
  visible: boolean;
  time: number;
  onDoubleTap: () => void;
  onClick?: () => void;
}

export const Rewind: React.FC<Props> = ({direction, visible, time, onDoubleTap, onClick}) => {
  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onClick?.();
  };

  const handleDoubleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    onDoubleTap();
  };

  return (
    <div className={direction} onClick={handleClick} onDoubleClick={handleDoubleClick}>
      {visible && <RewindBubble time={time} />}
    </div>
  );
};
