import React, {type FC, useEffect, useRef} from 'react';
import {useParams} from 'react-router-dom';
import {useIntl} from 'react-intl';
import classNames from 'classnames';

import Icon from 'components/Icon';
import {type ClassroomUrlParams} from 'common/paths';

import useCanPlayTogether from './useCanPlayTogether';

interface Props {
  className?: string;
  active: boolean;
  preview?: boolean;
  onClick: (e: React.SyntheticEvent) => void;
  reset: () => void;
}

const PlayTogether: FC<Props> = React.memo(({className, active, preview, onClick, reset}) => {
  const intl = useIntl();
  const {studentTeacherId} = useParams<ClassroomUrlParams>();

  const prevCanPlayTogether = useRef(false);

  const canPlayTogether = useCanPlayTogether({preview, studentTeacherId});

  useEffect(() => {
    if (!canPlayTogether && prevCanPlayTogether.current && active) {
      reset();
    }

    prevCanPlayTogether.current = canPlayTogether;
  }, [reset, active, canPlayTogether]);

  return (
    <button
      className={classNames('btn play-together', className, {active})}
      onClick={onClick}
      title={intl.formatMessage({id: 'File.PlayTogether'})}
      disabled={!canPlayTogether}
    >
      <Icon name="virc-listen-together" />
    </button>
  );
});

export default PlayTogether;
