import React from 'react';
import {connect} from 'react-redux';
import ReactYoutube from 'react-youtube';
import {injectIntl, type WrappedComponentProps} from 'react-intl';
import {captureMessage, withScope} from '@sentry/react';
import classNames from 'classnames';

import {type AppState} from 'store/interface';
import * as toastr from 'components/toastr';
import {isMobileWebView} from 'helpers/browser';

import {VideoSourceType} from '../../interface';
import VideoLoaderView from '../VideoLoaderView';
import {videoMessages} from '../messages';

import './styles.scss';

export enum YoutubeReadyState {
  UNINITIALIZED = 'uninitialized',
  READY = 'ready',
  LOADING = 'loading',
  ERROR = 'error'
}

export interface YT {
  mute(): void;
  pauseVideo(): void;
  playVideo(): void;
  stopVideo(): void;
  getDuration(): number;
  getPlayerState(): number;
}

interface StateProps {
  sourceId: string;
}

interface OwnProps extends WrappedComponentProps {
  id: number;
  start?: number;
  end?: number;
  isActive: boolean;
  playerId: string;
  videoRequestError?: boolean;
  reloadVideo(): void;
  activatePlayer(playerId: string): void;
  deactivatePlayer(playerId: string): void;
}

interface Props extends OwnProps, StateProps {}

interface State {
  readyState: YoutubeReadyState;
}

class YoutubePlayer extends React.Component<Props, State> {
  public state: State = {readyState: YoutubeReadyState.UNINITIALIZED};

  private player: YT;

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.isActive && !this.props.isActive) {
      this.player.stopVideo();
    }
  }

  public render() {
    const {sourceId, videoRequestError, start, end} = this.props;
    const isLoading = !sourceId || this.state.readyState === YoutubeReadyState.UNINITIALIZED;
    const className = classNames('youtube-player-wrapper', {loading: isLoading});
    const fs = isMobileWebView() ? 0 : 1;
    return (
      <div className={className}>
        {sourceId && (
          <ReactYoutube
            key={sourceId}
            onPlay={this.onPlay}
            onEnd={this.onEnd}
            onError={this.onError}
            onReady={this.onReady}
            videoId={sourceId}
            opts={{
              playerVars: {
                start,
                end,
                fs
              }
            }}
          />
        )}
        {isLoading && (
          <VideoLoaderView hasError={videoRequestError} reload={this.props.reloadVideo} />
        )}
      </div>
    );
  }

  private onPlay = () => this.props.activatePlayer(this.props.playerId);
  private onEnd = () => this.props.deactivatePlayer(this.props.playerId);

  private onError = (e: {data: number}) => {
    this.player.stopVideo();
    this.props.deactivatePlayer(this.props.playerId);
    toastr.error('', this.props.intl.formatMessage(videoMessages.PlaybackError));
    withScope(scope => {
      scope.setExtras({
        data: e.data,
        video: {
          id: this.props.id,
          source: VideoSourceType.YOUTUBE,
          sourceId: this.props.sourceId
        }
      });
      captureMessage('YouTube Player onError event', 'warning');
    });
  };

  private onReady = (e: {target: YT}) => {
    const {readyState} = this.state;
    this.player = e.target;
    if (readyState === YoutubeReadyState.UNINITIALIZED) {
      this.setState({readyState: YoutubeReadyState.READY});
      return;
    }
  };
}

const mapStateToProps = (state: AppState, {playerId}: OwnProps) => {
  const player = state.media.video.players.find(p => p.id === playerId);
  return {sourceId: player ? player.sourceId : undefined};
};

const Connected = connect(mapStateToProps)(YoutubePlayer);

export default injectIntl(Connected);
