import React, {useCallback, useEffect, useState} from 'react';
import {useFocused} from '@englex/paint-react';
import isHotkey from 'is-hotkey';

import {Direction} from './types';
import {Rewind} from './Rewind';

import './VideoRewind.scss';

interface State {
  time: number;
  direction?: Direction;
}

const offset = 10;

const initState = {time: 0};

interface Props {
  isActive: boolean;
  onChange: (direction: Direction, offset: number) => void;
  onClick?: () => void;
}

export const VideoRewind: React.FC<Props> = React.memo(({isActive, onChange, onClick}) => {
  const isFocused = useFocused();

  const [state, setState] = useState<State>(initState);

  const backward = useCallback(() => {
    setState(() => ({time: offset, direction: Direction.Backward}));
    onChange(Direction.Backward, offset);
  }, [onChange]);

  const forward = useCallback(() => {
    setState(() => ({time: offset, direction: Direction.Forward}));
    onChange(Direction.Forward, offset);
  }, [onChange]);

  const reset = useCallback(() => setState(initState), []);

  useEffect(() => {
    if (!state.direction) return;

    const timeout = setTimeout(reset, 1000);

    return () => clearTimeout(timeout);
  }, [reset, state]);

  useEffect(() => {
    const keydownEventListener = (event: KeyboardEvent) => {
      if (!isActive || !isFocused) return;

      if (isHotkey('arrowleft', event)) {
        event.preventDefault();
        backward();
      }

      if (isHotkey('arrowright', event)) {
        event.preventDefault();
        forward();
      }
    };

    document.addEventListener('keydown', keydownEventListener);

    return () => {
      document.removeEventListener('keydown', keydownEventListener);
    };
  }, [isActive, isFocused, forward, backward]);

  const {time, direction} = state;

  return (
    <div className="video-rewind">
      <Rewind
        direction={Direction.Backward}
        visible={direction === Direction.Backward}
        time={time}
        onDoubleTap={backward}
        onClick={onClick}
      />
      <Rewind
        direction={Direction.Forward}
        visible={direction === Direction.Forward}
        time={time}
        onDoubleTap={forward}
        onClick={onClick}
      />
    </div>
  );
});
