import React from 'react';
import {injectIntl, type WrappedComponentProps} from 'react-intl';
import {connect} from 'react-redux';
import {type Action} from 'redux';
import {type Dispatch} from 'redux-axios-middleware';

import {type AppState} from 'store/interface';
import {
  activatePlayer,
  deactivatePlayer,
  registerVideoPlayer,
  requestVideoFile,
  unregisterVideoPlayer
} from 'store/media/video/actions';
import {type AxiosResponseAction} from 'services/axios/interface';
import * as toastr from 'components/toastr';
import {genKey} from 'components/Slate/utils';
import {type VideoFile} from 'store/exercise/player/widgets/Video/interface';

import YoutubePlayer from './YoutubePlayer';

import './styles.scss';

interface StateProps {
  activePlayerId?: string;
}

interface DispatchProps {
  registerVideoPlayer(playerId: string, sourceId: string): void;
  requestVideoFile(id: number): Promise<AxiosResponseAction<VideoFile>>;
  unregisterVideoPlayer(playerId: string): void;
  activatePlayer(playerId: string): void;
  deactivatePlayer(playerId: string): void;
}

interface OwnProps {
  id: number;
  start?: number;
  end?: number;
}

interface Props extends OwnProps, StateProps, DispatchProps, WrappedComponentProps {}

interface State {
  requestError?: boolean;
}

class Youtube extends React.Component<Props, State> {
  public state: State = {};

  private readonly id: string;

  private get isActive() {
    return this.id === this.props.activePlayerId;
  }

  public constructor(props: Props) {
    super(props);
    this.id = genKey();
  }

  public componentDidMount() {
    this.requestVideoFile();
  }

  public componentWillUnmount() {
    this.props.unregisterVideoPlayer(this.id);
  }

  public render() {
    const {id, activatePlayer, deactivatePlayer, start, end} = this.props;
    return (
      <YoutubePlayer
        id={id}
        start={start}
        end={end}
        isActive={this.isActive}
        playerId={this.id}
        videoRequestError={this.state.requestError}
        reloadVideo={this.requestVideoFile}
        activatePlayer={activatePlayer}
        deactivatePlayer={deactivatePlayer}
      />
    );
  }

  private requestVideoFile = async () => {
    if (this.state.requestError) {
      this.setState({requestError: undefined});
    }
    let response: AxiosResponseAction<VideoFile> | null = null;
    try {
      response = await this.props.requestVideoFile(this.props.id);
      this.props.registerVideoPlayer(this.id, response.payload.data.sourceId);
    } catch {
      this.setState({requestError: true});
      toastr.error('', this.props.intl.formatMessage({id: 'Common.SomethingWrong'}));
    }
  };
}

const mapStateToProps = ({media: {video}}: AppState): StateProps => ({
  activePlayerId: video.activePlayerId
});

const mapDispatchToProps = (dispatch: Dispatch<Action, AppState>): DispatchProps => ({
  registerVideoPlayer: (playerId: string, sourceId: string) =>
    dispatch(registerVideoPlayer(playerId, sourceId)),
  requestVideoFile: (id: number) => dispatch(requestVideoFile(id)),
  unregisterVideoPlayer: (playerId: string) => dispatch(unregisterVideoPlayer(playerId)),
  activatePlayer: (playerId: string) => dispatch(activatePlayer(playerId)),
  deactivatePlayer: (playerId: string) => dispatch(deactivatePlayer(playerId))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Youtube));
