import {type List} from 'immutable';

import {xplayerPrefix} from 'store/exercise/player/actionTypes';
import {type WidgetAction} from 'store/exercise/player/interface';
import {xpreviewPrefix} from 'store/exercise/player/preview/actionTypes';

import {
  FLIP_CARDS_ADD_CARD,
  FLIP_CARDS_CHANGE_IMAGE,
  FLIP_CARDS_CHANGE_DEFAULT_FLIPPED,
  FLIP_CARDS_CHANGE_TEXT,
  FLIP_CARDS_DELETE_CARD,
  FLIP_CARDS_FLIP_CARD,
  FLIP_CARDS_CHANGE_CARD_SIZE_TYPE,
  FLIP_CARDS_MOVE_CARD,
  FLIP_CARDS_ROLL_BACK_CARDS
} from './actionTypes';
import {type CardSizeType} from '../XWordPictureSet/XPictureSet/interface';
import type XFlipCardsCardRecord from './XFlipCardsCardRecord';
import {type XWidgetAction} from '../../actions/interface';

export interface FlipCardsCardAction extends XWidgetAction {
  cardId: string;
}

export interface FlipCardsCardPlayerAction extends WidgetAction {
  cardId: string;
}

export interface FlipCardsChangeImageAction extends FlipCardsCardAction {
  imageId: number;
}

export interface FlipCardsChangeCardSizeTypeAction extends FlipCardsCardAction {
  cardSizeType: CardSizeType;
}

export interface FlipCardsChangeTextAction extends FlipCardsCardAction {
  text: string;
}

export interface FlipCardsMoveCard extends XWidgetAction {
  moveCardIndex: number;
  targetIndex: number;
}

export interface FlipCardsRollBackCards extends XWidgetAction {
  cards: List<XFlipCardsCardRecord>;
}

export const changeDefaultFlipped = (xwidgetId: string): XWidgetAction => ({
  type: FLIP_CARDS_CHANGE_DEFAULT_FLIPPED,
  xwidgetId
});

export const addCard = (xwidgetId: string): XWidgetAction => ({
  type: FLIP_CARDS_ADD_CARD,
  xwidgetId
});

export const deleteCard = (xwidgetId: string, cardId: string): FlipCardsCardAction => ({
  type: FLIP_CARDS_DELETE_CARD,
  xwidgetId,
  cardId
});

export const flipCard = (
  widgetId: string,
  cardId: string,
  preview?: boolean
): FlipCardsCardPlayerAction => {
  const prefix = preview ? xpreviewPrefix : xplayerPrefix;
  return {
    type: `${prefix}${FLIP_CARDS_FLIP_CARD}`,
    widgetId,
    cardId,
    preview
  };
};

export const changeImage = (
  xwidgetId: string,
  cardId: string,
  imageId: number
): FlipCardsChangeImageAction => ({
  type: FLIP_CARDS_CHANGE_IMAGE,
  xwidgetId,
  cardId,
  imageId
});

export const flipCardsChangeCardSizeType = (
  xwidgetId: string,
  cardId: string,
  cardSizeType: CardSizeType
): FlipCardsChangeCardSizeTypeAction => ({
  type: FLIP_CARDS_CHANGE_CARD_SIZE_TYPE,
  xwidgetId,
  cardId,
  cardSizeType
});

export const changeText = (
  xwidgetId: string,
  cardId: string,
  text: string
): FlipCardsChangeTextAction => ({
  type: FLIP_CARDS_CHANGE_TEXT,
  xwidgetId,
  cardId,
  text
});

export const moveCard = (
  xwidgetId: string,
  moveCardIndex: number,
  targetIndex: number
): FlipCardsMoveCard => ({
  type: FLIP_CARDS_MOVE_CARD,
  xwidgetId,
  moveCardIndex,
  targetIndex
});

export const rollBackCards = (
  xwidgetId: string,
  cards: List<XFlipCardsCardRecord>
): FlipCardsRollBackCards => ({
  type: FLIP_CARDS_ROLL_BACK_CARDS,
  xwidgetId,
  cards
});
