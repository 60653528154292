import React, {type FC} from 'react';

import {formatSecondsToString} from 'services/common-methods';
import {type PlayStatusV2} from 'components/media/interface';

interface CurrentTime {
  currentTime: number;
}

const TimeDisplay: FC<CurrentTime> = ({currentTime}) => (
  <span>{formatSecondsToString(currentTime)} / </span>
);

interface DurationProps extends CurrentTime {
  duration?: number;
  playStatus?: PlayStatusV2;
}

export const Duration: FC<DurationProps> = ({currentTime, duration, playStatus}) => (
  <span className="video-duration">
    {playStatus !== undefined && <TimeDisplay currentTime={currentTime} />}
    {formatSecondsToString(duration || 0)}
  </span>
);

export const Buffered: FC<{buffered?: {offset: number; length: number}}> = ({buffered}) =>
  !buffered ? null : (
    <div
      className="buffered-amount"
      style={{width: buffered.length * 100 + '%', left: buffered.offset * 100 + '%'}}
    />
  );
