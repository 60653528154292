import {type Editor, type RenderAttributes} from '@englex/slate-react';
import React from 'react';
import {useSelector} from 'react-redux';

import {type WidgetComponentProps, WidgetType} from 'store/exercise/player/interface';
import {
  type StyledListProperties,
  type StyledListStyles
} from 'store/exercise/player/widgets/List/StyledList/interface';
import type StyledListRecord from 'store/exercise/player/widgets/List/StyledList/StyledListRecord';
import {type AppState} from 'store/interface';
import {getWidgetProps} from 'components/Slate/interface';

import {HorizontalItem} from '../HorizontalItem/HorizontalItem';
import {VerticalBulletedItem} from '../VerticalBulletedItem/VerticalBulletedItem';
import {VerticalNumberedItem} from '../VerticalNumberedItem/VerticalNumberedItem';
import {getXpreviewExercise} from '../../utils';

interface Props {
  editor: Editor;
  index: number;
  id: string;
  styleName: StyledListStyles;
  children: React.ReactNode;
  listType: WidgetType;
  attributes: RenderAttributes;
  isActive: boolean;
  withNumbering: boolean;
  twoColumns: boolean;
  onClick?: () => void;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
}

export const StyledListFactory: React.FC<Props> = ({
  editor,
  index,
  attributes,
  id: itemId,
  styleName,
  listType,
  isActive,
  withNumbering,
  twoColumns,
  children,
  onClick,
  onPaste
}) => {
  const {
    widget: {id: widgetId},
    exerciseId,
    preview,
    isModal
  } = editor.query(getWidgetProps) as WidgetComponentProps<StyledListProperties>;

  const widget = useSelector((state: AppState) => {
    const exercise = !preview
      ? state.xplayer?.exercises.get(exerciseId)
      : getXpreviewExercise(state, exerciseId);

    return exercise?.widgets?.find((x: StyledListRecord) => x.id === widgetId) as StyledListRecord;
  });

  const activated = isModal ? isActive : widget?.values?.includes(itemId);

  if (listType === WidgetType.HORIZONTAL_STYLED_LIST)
    return (
      <HorizontalItem
        attributes={attributes}
        id={itemId}
        styleName={styleName}
        children={children}
        isActive={activated}
        withNumbering={withNumbering}
        index={index}
        onClick={onClick}
        onPaste={onPaste}
      />
    );

  if (styleName.includes('numbered'))
    return (
      <VerticalNumberedItem
        index={index}
        attributes={attributes}
        id={itemId}
        isActive={activated}
        styleName={styleName}
        twoColumns={twoColumns}
        children={children}
        onClick={onClick}
        onPaste={onPaste}
      />
    );

  return (
    <VerticalBulletedItem
      attributes={attributes}
      id={itemId}
      styleName={styleName}
      twoColumns={twoColumns}
      isActive={activated}
      children={children}
      onClick={onClick}
      onPaste={onPaste}
    />
  );
};
