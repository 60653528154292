import React, {type FC} from 'react';
import {type Dispatch} from 'redux-axios-middleware';
import {connect} from 'react-redux';
import {injectIntl, type WrappedComponentProps} from 'react-intl';
import {useLocation, useParams} from 'react-router-dom';
import {type Action} from 'redux';

import {type AppState, type Role} from 'store/interface';
import {
  activatePlayer,
  changeVolume as changeVolumeActionCreator,
  deactivatePlayer,
  registerVideoPlayer,
  requestVideoFile,
  setDuration,
  unregisterVideoPlayer
} from 'store/media/video/actions';
import * as toastr from 'components/toastr';
import {type AxiosResponseAction} from 'services/axios/interface';
import {type VideoFile} from 'store/exercise/player/widgets/Video/interface';

import VideoPlayer from './components';
import './styles.scss';
import {type MediaContext, type MediaType} from '../../interface';
import {urlOpened, publishOpenUrl, setOpeningUrlForPartner} from '../../../../common/action';

interface StateProps {
  activePlayerId?: string;
  isMobile?: boolean;
  role?: Role;
  volume: number;
}

interface RouteProps {
  courseInstanceId: string;
  pathname: string;
}

interface DispatchProps {
  activatePlayer(playerId: string): void;
  deactivatePlayer(playerId: string): void;
  registerVideoPlayer(playerId: string, sourceId: string, url: string, posterUrls?: string[]): void;
  requestVideoFile(id: number): Promise<AxiosResponseAction<VideoFile>>;
  unregisterVideoPlayer(playerId: string): void;
  changeVolume(volume: number): void;
  setDuration(playerId: string, duration: number): void;
  showActionTogetherError(errorMessage: string): void;
  actionTogether(data: {id: string; mediaType: MediaType; mediaContext: MediaContext}): void;
}

interface OwnProps {
  id: number;
  posterId?: number;
  preview?: boolean;
  widgetId: string;
}

interface Props extends OwnProps, StateProps, DispatchProps, WrappedComponentProps, RouteProps {}

interface State {
  requestError?: boolean;
}

class EnglexPlayerView extends React.Component<Props, State> {
  public state: State = {};
  private readonly id = `${this.props.widgetId}::${this.props.id}`;

  private get isActive() {
    return this.id === this.props.activePlayerId;
  }

  public componentDidMount() {
    this.requestVideoFile();
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.posterId !== this.props.posterId) {
      this.props.unregisterVideoPlayer(this.id);
      this.requestVideoFile();
    }
  }

  public componentWillUnmount() {
    this.props.unregisterVideoPlayer(this.id);
  }

  public render() {
    const {
      widgetId,
      activatePlayer,
      activePlayerId,
      changeVolume,
      deactivatePlayer,
      isMobile,
      preview,
      role,
      volume,
      actionTogether,
      showActionTogetherError
    } = this.props;
    return (
      <VideoPlayer
        id={this.id}
        videoId={this.props.id}
        widgetId={widgetId}
        isActive={this.isActive}
        isMobile={isMobile}
        activePlayerId={activePlayerId}
        role={role}
        volume={volume}
        preview={preview}
        requestError={this.state.requestError}
        changeVolume={changeVolume}
        setDuration={this.setDuration}
        reload={this.requestVideoFile}
        activatePlayer={activatePlayer}
        deactivatePlayer={deactivatePlayer}
        actionTogether={actionTogether}
        showActionTogetherError={showActionTogetherError}
      />
    );
  }

  private requestVideoFile = async () => {
    if (this.state.requestError) {
      this.setState({requestError: undefined});
    }
    let response: AxiosResponseAction<VideoFile> | null = null;
    let posterUrls: string[] | undefined;
    try {
      response = await this.props.requestVideoFile(this.props.id);
      const {sourceId, posters, url} = response.payload.data;
      if (posters) {
        const poster = posters.find(p => p.id === this.props.posterId);
        poster && (posterUrls = poster.urls);
      }
      this.props.registerVideoPlayer(this.id, sourceId, url, posterUrls);
    } catch {
      this.setState({requestError: true});
      toastr.error('', this.props.intl.formatMessage({id: 'Common.SomethingWrong'}));
    }
  };

  private setDuration = (duration: number) => this.props.setDuration(this.id, duration);
}

const mapStateToProps = ({layout, media: {video}, user}: AppState): StateProps => ({
  activePlayerId: video.activePlayerId,
  isMobile: layout.isMobile,
  role: user.role,
  volume: video.volume
});

function actionTogether(data: {
  courseId: number;
  url: string;
  id: string;
  mediaType: MediaType;
  mediaContext: MediaContext;
}) {
  return (dispatch: Dispatch<Action, AppState>, getState: () => AppState) => {
    const partnerSession = getState().rtc.partnerSessionId;

    if (partnerSession) {
      dispatch(publishOpenUrl({...data, partnerSession}));
      dispatch(setOpeningUrlForPartner());
    }
  };
}

const mapDispatchToProps = (
  dispatch: Dispatch<Action, AppState>,
  {courseInstanceId, pathname}: RouteProps
): DispatchProps => {
  const courseId = Number(courseInstanceId);
  const url = pathname;

  return {
    registerVideoPlayer: (playerId: string, sourceId: string, url: string, posterUrls: string[]) =>
      dispatch(registerVideoPlayer(playerId, sourceId, url, posterUrls)),
    unregisterVideoPlayer: (playerId: string) => dispatch(unregisterVideoPlayer(playerId)),
    activatePlayer: (playerId: string) => dispatch(activatePlayer(playerId)),
    deactivatePlayer: (playerId: string) => dispatch(deactivatePlayer(playerId)),
    requestVideoFile: (id: number) => dispatch(requestVideoFile(id)),
    changeVolume: (volume: number) => dispatch(changeVolumeActionCreator(volume)),
    setDuration: (playerId: string, duration: number) => dispatch(setDuration(playerId, duration)),
    actionTogether: (data: {id: string; mediaType: MediaType; mediaContext: MediaContext}) =>
      dispatch(actionTogether({courseId, url, ...data})),
    showActionTogetherError: (errorMessage: string) => {
      dispatch(urlOpened());
      toastr.error('', errorMessage);
    }
  };
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(injectIntl(EnglexPlayerView));

const EnglexPlayer: FC<OwnProps> = props => {
  const {courseId} = useParams<{courseId: string}>();
  const {pathname} = useLocation();

  return <Connected {...props} courseInstanceId={courseId!} pathname={pathname} />;
};
export default EnglexPlayer;
