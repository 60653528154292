import React, {Component, type SyntheticEvent} from 'react';
import {captureMessage, withScope} from '@sentry/react';
import {injectIntl, type WrappedComponentProps} from 'react-intl';
import classNames from 'classnames';
import RetinaImage from '@englex/react-retina-image';
import Button from 'react-bootstrap/lib/Button';
import Bowser from 'bowser';

import {type Role} from 'store/interface';
import {playMedia} from 'helpers/playMedia';
import {isSafari} from 'helpers/browser';
import Throttle from 'common/Throttle';
import {
  LOADING,
  PAUSE,
  PLAYING,
  type PlayStatusV2,
  VideoSourceType
} from 'components/media/interface';
import {videoMessages} from 'components/media/VideoPlayer/messages';
import Icon from 'components/Icon';
import * as toastr from 'components/toastr';

import Controls from './Controls';
import VideoSlider from './Controls/VideoSlider';
import {Buffered, Duration} from './Controls/UtilityComponents';

interface Props extends WrappedComponentProps {
  id: string;
  currentTime: number;
  player: React.RefObject<HTMLVideoElement>;
  isActive: boolean;
  activePlayerId?: string;
  forceRestart?: true;
  isFullscreen: boolean;
  isMobile?: boolean;
  duration?: number;
  playStatus?: PlayStatusV2;
  posterUrls?: string[];
  preview?: boolean;
  playbackRate?: number;
  shouldShowControls: boolean;
  isTogether: boolean;
  role?: Role;
  url: string;
  volume: number;
  reload(): void;
  stop(): void;
  changeVolume(volume: number): void;
  changePlaybackRate(playbackRate: number): void;
  clearForceRestart(): void;
  setDuration(duration: number): void;
  setStatus(playStatus?: PlayStatusV2): void;
  activatePlayer(playerId: string): void;
  deactivatePlayer(playerId: string): void;
  onVolumePopup(volumePoppedUp: boolean): void;
  onPlaybackRatePopup(poppedUp?: boolean): void;
  showControls(): void;
  toggleControlState(newState: boolean): void;
  toggleFullscreen(): void;
  triggerPlayPause(): void;
  toggleIsTogether(e: React.SyntheticEvent<HTMLButtonElement>): void;
  resetIsTogether(): void;
  setCurrentTime(timestamp: number): void;
  onChangeCurrentTime(timestamp: number): void;
}

interface State {
  allowDockBtn: boolean;
  showDockBtn: boolean;
  buffered?: {offset: number; length: number};
  controlFocused: boolean;
  pictureInPicture: boolean;
}

class Video extends Component<Props, State> {
  public state: State = {
    allowDockBtn: false,
    showDockBtn: false,
    controlFocused: false,
    pictureInPicture: false
  };
  private dockBtnTimeout: NodeJS.Timeout | null;
  private waitPlay: boolean = false;
  private waitPause: boolean = false;
  private readonly throttle = new Throttle();

  public componentDidMount() {
    if (this.props.player.current && this.props.isMobile) {
      this.props.player.current.volume = 1;
    }
  }

  public componentDidUpdate(prevProps: Props) {
    this.shouldChangeCurrentTime();
    this.shouldPlay(prevProps);
    this.shouldPause(prevProps);
    this.shouldStop(prevProps);
    this.shouldChangeVolume();
    this.shouldDispatch(prevProps);
    this.shouldChangePlaybackRate(prevProps);
    this.shouldSetDuration();
  }

  public componentWillUnmount(): void {
    if (this.props.player.current) {
      if (this.props.player.current === document.pictureInPictureElement) {
        document.exitPictureInPicture?.();
      }
      if (this.dockBtnTimeout) clearTimeout(this.dockBtnTimeout);
      this.props.player.current.removeEventListener(
        'leavepictureinpicture',
        this.clearPictureInPictureState
      );
    }
  }

  public render() {
    const {
      currentTime,
      activePlayerId,
      changeVolume,
      changePlaybackRate,
      duration,
      id,
      isFullscreen,
      isMobile,
      preview,
      role,
      onVolumePopup,
      onPlaybackRatePopup,
      playStatus,
      playbackRate,
      posterUrls,
      showControls,
      toggleControlState,
      triggerPlayPause,
      url,
      volume,
      children
    } = this.props;
    const {allowDockBtn, showDockBtn, buffered, pictureInPicture} = this.state;
    return (
      <>
        <div
          className="positioner"
          onClick={this.onClick}
          onMouseMove={showControls}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
        >
          {posterUrls && !playStatus && (
            <RetinaImage src={posterUrls} className="poster" onClick={triggerPlayPause} />
          )}
          <video
            className={classNames('enlx-video-player', {invisible: !playStatus && posterUrls})}
            src={url}
            preload="metadata"
            ref={this.props.player}
            // listeners
            onEnded={this.end}
            onLoadedMetadata={this.onLoadedMetadata}
            onPlaying={this.play}
            onPlay={this.play}
            onWaiting={this.onWaiting}
            onError={this.onError}
            onPause={this.pause}
            onCanPlay={this.onCanPlay}
            onSeeking={this.setCurrentTime}
            onTimeUpdate={this.setCurrentTime}
            onProgress={this.onProgress}
          />
          {allowDockBtn && (
            <Button
              className={classNames('dock-btn', {visible: showDockBtn && !isFullscreen})}
              onClick={this.onDockButtonClick}
            >
              <Icon name={`virc-${pictureInPicture ? 'out' : 'in'}_dock`} />
            </Button>
          )}
          {children}
        </div>
        <Controls
          isTogether={this.props.isTogether}
          playStatus={playStatus}
          stop={this.end}
          changeVolume={changeVolume}
          playbackRate={playbackRate}
          changePlaybackRate={changePlaybackRate}
          id={id}
          isFullscreen={isFullscreen}
          isMobile={isMobile}
          preview={preview}
          showControls={showControls}
          volume={volume}
          onVolumePopup={onVolumePopup}
          onPlaybackRatePopup={onPlaybackRatePopup}
          role={role}
          toggleFullscreen={this.toggleFullscreen}
          toggleControlState={toggleControlState}
          triggerPlayPause={triggerPlayPause}
          volumeAndFullscreenDisabled={!activePlayerId ? false : !playStatus}
          toggleIsTogether={this.props.toggleIsTogether}
          resetIsTogether={this.props.resetIsTogether}
        >
          <div className="slider">
            {duration && (
              <VideoSlider
                isTogether={this.props.isTogether}
                className="video-widget-slider"
                duration={duration}
                currentTime={currentTime}
                isFullscreen={isFullscreen}
                isMobile={isMobile}
                playStatus={playStatus}
                play={this.play}
                pause={this.pause}
                stop={this.stop}
                updateTime={this.updateTime}
              >
                <Buffered buffered={buffered} />
              </VideoSlider>
            )}
          </div>
          <Duration currentTime={currentTime} duration={duration} playStatus={playStatus} />
        </Controls>
      </>
    );
  }

  private updateTime = (currentTime: number) =>
    this.throttle.throttleAction(() => {
      if (this.props.player.current) {
        this.props.onChangeCurrentTime(currentTime);
      }
    });

  private setCurrentTime = (e: React.SyntheticEvent<HTMLVideoElement>) =>
    this.props.setCurrentTime(e.currentTarget.currentTime);

  private onMouseEnter = () => {
    if (!this.state.allowDockBtn || this.props.isFullscreen) return;
    this.setState({showDockBtn: true});
    if (this.dockBtnTimeout) {
      clearTimeout(this.dockBtnTimeout);
      this.dockBtnTimeout = null;
    }
  };

  private onMouseLeave = () => {
    if (!this.state.allowDockBtn || this.props.isFullscreen) return;
    this.dockBtnTimeout = setTimeout(() => {
      this.setState({showDockBtn: false});
      this.dockBtnTimeout = null;
    }, 1000);
  };

  private exitPictureInPicture = async () => {
    if (document.pictureInPictureElement) await document.exitPictureInPicture?.();
  };

  private onDockButtonClick = async (e: React.MouseEvent<Button, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    if (
      this.props.player.current &&
      this.props.player.current !== document.pictureInPictureElement
    ) {
      await this.exitPictureInPicture();
      await this.props.player.current.requestPictureInPicture?.();
      this.props.player.current.addEventListener(
        'leavepictureinpicture',
        this.clearPictureInPictureState
      );
      this.setState({pictureInPicture: true});
    } else await this.exitPictureInPicture();
  };

  private clearPictureInPictureState = () => {
    if (this.props.player.current) {
      this.props.player.current.removeEventListener(
        'leavepictureinpicture',
        this.clearPictureInPictureState
      );
      this.setState({pictureInPicture: false});
    }
  };

  private toggleFullscreen = async () => {
    await this.exitPictureInPicture();
    this.props.toggleFullscreen();
  };
  // this event listener exists simply because safari on some circumstances can skip
  // firing onPlaying after loading provides enough data to play, which caused infinite
  // LOADING state in reducer -> infinite loading indicator.
  private onCanPlay = () => {
    if (isSafari() && this.props.playStatus === LOADING && this.props.player.current)
      this.props.setStatus(PLAYING);
  };

  private onError = (e: SyntheticEvent) => {
    this.stop();
    toastr.error('', this.props.intl.formatMessage(videoMessages.PlaybackError));
    withScope(scope => {
      scope.setExtras({
        event: e,
        video: {
          id: this.props.id,
          source: VideoSourceType.SELECTEL,
          url: this.props.url
        }
      });
      captureMessage('Selectel Player onError event', 'warning');
    });
  };

  private onLoadedMetadata = () => {
    if (this.props.player.current) {
      this.props.player.current.playbackRate = this.props.playbackRate || 1;
      this.props.setDuration(Math.round(this.props.player.current.duration));
      if (
        Bowser.getParser(window.navigator.userAgent).getBrowser().name === 'Chrome' &&
        !this.props.isMobile
      ) {
        this.setState({allowDockBtn: true});
      }
    }
  };

  private play = () => {
    if (this.props.playStatus !== PLAYING) this.props.setStatus(PLAYING);
  };

  private pause = () => {
    const {playStatus, setStatus} = this.props;
    if (playStatus && playStatus !== PAUSE) {
      if (this.waitPlay) {
        this.waitPause = true;
      } else {
        setStatus(PAUSE);
      }
    }
  };

  private stop = () => {
    if (this.props.player.current) {
      this.props.stop();
    }
  };

  private onWaiting = () => {
    const {playStatus, setStatus} = this.props;
    if (playStatus && playStatus !== LOADING) setStatus(LOADING);
  };

  private onProgress = () => {
    const {duration} = this.props;
    if (this.props.player.current && duration) {
      const {buffered, currentTime} = this.props.player.current;
      for (let i = 0; i < buffered.length; i++) {
        const [start, end] = [buffered.start(i), buffered.end(i)];
        if (end >= currentTime && start <= currentTime) {
          this.setState({
            buffered: {offset: start / duration, length: (end - start) / duration}
          });
          break;
        }
      }
    }
  };

  private end = () => {
    this.stop();
  };

  private shouldPlay(prevProps: Props) {
    if (prevProps.playStatus !== PLAYING && this.props.playStatus === PLAYING) this.playMedia();
  }

  private shouldChangeCurrentTime() {
    const {clearForceRestart, forceRestart} = this.props;
    if (this.props.player.current && forceRestart) {
      this.props.player.current.currentTime = 0;
      return clearForceRestart();
    }
  }

  private shouldPause(prevProps: Props) {
    if (prevProps.playStatus !== PAUSE && this.props.playStatus === PAUSE)
      this.props.player.current?.pause();
  }

  private shouldStop(prevProps: Props) {
    if (prevProps.playStatus && !this.props.playStatus) this.stop();
  }

  private shouldChangePlaybackRate(prevProps: Props) {
    const {playbackRate} = this.props;
    if (this.props.player.current && prevProps.playbackRate !== playbackRate) {
      this.props.player.current.playbackRate = playbackRate || 1;
    }
  }

  private shouldChangeVolume() {
    const {isMobile, volume} = this.props;
    if (isMobile) return;
    if (this.props.player.current && volume !== this.props.player.current.volume)
      this.props.player.current.volume = volume;
  }

  private shouldDispatch({isActive: wasActive, playStatus: prevStatus}: Props) {
    const {activatePlayer, deactivatePlayer, id, isActive, playStatus} = this.props;
    if (!prevStatus && playStatus) activatePlayer(id);
    if (wasActive && !isActive) this.stop();
    if (prevStatus && !playStatus) {
      this.stop();
      deactivatePlayer(id);
    }
  }

  private shouldSetDuration() {
    if (!this.props.preview && !this.props.duration && this.props.player.current?.duration) {
      this.props.setDuration(Math.round(this.props.player.current.duration));
    }
  }

  private playMedia = () => {
    if (this.props.player.current) {
      this.waitPlay = true;
      playMedia(this.props.player.current).finally(() => {
        if (this.waitPause) {
          this.props.setStatus(PAUSE);
          this.waitPause = false;
        }
        this.waitPlay = false;
      });
    }
  };

  private onClick = (event: React.MouseEvent) => {
    event.preventDefault();

    if (!this.props.shouldShowControls) {
      return this.props.showControls();
    }
    this.props.triggerPlayPause();
  };
}

export default injectIntl(Video);
