import React from 'react';
import {Portal} from 'react-portal';
import classNames from 'classnames';
import './VolumeSlider.scss';

interface Props {
  volume?: number;
  changeVolume: (volume: number) => void;
  isFullscreen?: boolean;
  setValue: (value: number) => void;
}

interface State {
  mouseDown: boolean;
}

export default class VolumeSlider extends React.PureComponent<Props, State> {
  private static percentage: number = 100;

  public state: State = {mouseDown: false};

  protected progress: HTMLDivElement;

  public render() {
    const [{isFullscreen, volume}, {mouseDown}] = [this.props, this.state];
    const {ScrollHelper} = this;
    return (
      <div
        className="volume active"
        onClick={this.volumeClickHandle}
        onMouseMove={this.moveHandler}
        ref={this.progressRef}
      >
        <div
          className="progress-amount active"
          style={{
            height: volume !== undefined ? volume * VolumeSlider.percentage + '%' : '100%'
          }}
        >
          <div
            className="progress-drag-circle active"
            onMouseDown={this.dragCircleMouseDownHandler}
          />
        </div>
        {mouseDown && !isFullscreen ? (
          <Portal node={document.querySelector('body')}>
            <ScrollHelper />
          </Portal>
        ) : (
          <ScrollHelper />
        )}
      </div>
    );
  }

  private progressRef = (progress: HTMLDivElement) => {
    this.progress = progress;
  };

  private dragCircleMouseDownHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    window.addEventListener('mouseup', this.mouseUpEvent);
    this.volumeClickHandle(e);
    this.setState({mouseDown: true});
  };

  private mouseUpEvent = () => {
    this.setState({mouseDown: false}, () => {
      window.removeEventListener('mouseup', this.mouseUpEvent);
    });
  };

  private volumeClickHandle = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    const sliderBounds = this.progress.getBoundingClientRect();
    const value: number = Number((sliderBounds.bottom - e.pageY) / 80);
    this.props.setValue(value);
  };

  private moveHandler: (e: React.MouseEvent<HTMLDivElement>) => void = e => {
    e.preventDefault();
    e.stopPropagation();
    if (this.state.mouseDown) {
      this.volumeClickHandle(e);
    }
  };

  private ScrollHelper = () => (
    <div
      className={classNames('audio-scroll-helper', {
        'mouse-down': this.state.mouseDown,
        fullscreen: this.props.isFullscreen
      })}
      onClick={this.volumeClickHandle}
    />
  );
}
