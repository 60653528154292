import React, {Component, type FC, type PropsWithChildren, useEffect} from 'react';
import {findDOMNode} from 'react-dom';
import {Focusable, useFocused} from '@englex/paint-react';
import classNames from 'classnames';
import Button from 'react-bootstrap/lib/Button';
import {FormattedMessage, type WrappedComponentProps} from 'react-intl';
import isHotkey from 'is-hotkey';

import {type AxiosResponseAction} from 'services/axios/interface';
import {type CancellablePromise, makeCancellable} from 'helpers/cancellablePromise';
import {isMobileWebView, isSafari} from 'helpers/browser';
import {formatSecondsToString} from 'services/common-methods';
import {emitter, xAudioCurrentTimeChanged} from 'services/event-emitter';
import Icon from 'components/Icon';
import Spinner from 'components/Spinner';
import {openingUrlForStudentTimeout, xPlayerPortalWidgetPreview} from 'config/static';

import PlayerPortalWrapper from '../../../XPlayer/components/PlayerPortalWrapper';
import {
  type AudioFile,
  LOADING,
  type MediaContext,
  MediaContextAction,
  PAUSE,
  PLAYING
} from '../../interface';
import VolumeControl from '../containers/Exercise/VolumeControl';
import AudioSlider from '../containers/Exercise/AudioSlider';
import TimeDisplay from './components/TimeDisplay';
import {PlaybackRate} from './components/PlaybackRate';
import {errorMessages} from '../../_common/errorMessages';
import PlayTogether from '../../_common/PlayTogether';
import {MobileAudioWidgetPlayer} from './MobileAudioWidgetPlayer';
import {type AudioWidgetPlayerProps} from './interface';

import './AudioWidgetPlayer.scss';

type Props = PropsWithChildren<AudioWidgetPlayerProps> & WrappedComponentProps;

interface State {
  loadFileError?: boolean;
  isTogether: boolean;
}

class AudioWidgetPlayer extends Component<Props, State> {
  public state: State = {isTogether: false};

  private fileRequest?: CancellablePromise;

  private actionTogetherTimeout: null | NodeJS.Timeout = null;

  private audioCurrentTime: number = 0;

  private forwardBackwardTime: number = 10;

  private static scrollToWidget(widgetId: string) {
    Promise.resolve().then(() => {
      const widget = document.getElementById(`x-widget-${widgetId}`);
      widget?.scrollIntoView({block: 'start', behavior: 'smooth'});
    });
  }

  private get ppButton() {
    return !this.isActive || this.props.playStatus === PAUSE
      ? {handler: this.play, icon: 'play'}
      : {handler: this.pause, icon: 'pause'};
  }

  private get mediaContext(): MediaContext | undefined {
    return this.props.widgetId
      ? {widgetId: this.props.widgetId, mediaId: this.props.audioId}
      : undefined;
  }

  private get isCurrentWidget() {
    const {context, batchedMediaContext, widgetId} = this.props;

    const comparedWithContext = context?.widgetId ? context.widgetId === widgetId : true;
    const comparedWithBatchedContext = batchedMediaContext?.widgetId
      ? batchedMediaContext.widgetId === widgetId
      : true;

    return comparedWithContext && comparedWithBatchedContext;
  }

  private get isNewAudio() {
    return (
      !this.props.activeAudioFile ||
      this.props.activeAudioFile.id !== this.props.audioId ||
      !this.isCurrentWidget
    );
  }

  private get isActive() {
    const {activeAudioFile, audioId} = this.props;
    return activeAudioFile && audioId === activeAudioFile.id && this.isCurrentWidget;
  }

  private static get isIos() {
    if (!isSafari()) return false;
    return 'ontouchstart' in window;
  }

  private getClasses(hasError?: boolean) {
    const {compact, dark, isMobile, playStatus, audioFile} = this.props;
    return classNames('exercise-audio-widget-player', {
      'is-mobile': isMobile,
      compact,
      dark,
      loading: playStatus === LOADING,
      'has-error': hasError,
      'requesting-file': !hasError && !audioFile
    });
  }

  private get sliderClasses() {
    const {compact, playStatus} = this.props;
    return classNames({compact, loading: playStatus === LOADING});
  }

  private setCurrentTime = (currentTime: number) => {
    if (this.isActive) {
      this.audioCurrentTime = currentTime;
    }
  };

  public async componentDidUpdate(prevProps: Props) {
    const {
      role,
      audioFile,
      audioId,
      batchAudio,
      batchedMedia,
      changeActiveAudio,
      changePlayStatus,
      changeTimestamp,
      changePlaybackRate,
      playStatus,
      widgetId,
      batchedMediaContext
    } = this.props;

    if (audioId === prevProps.audioId && !audioFile && prevProps.audioFile) {
      this.sendFileRequest();
    }

    if (prevProps.batchedMedia && prevProps.batchedMedia !== batchedMedia) {
      this.clearActionTogetherTimeout();
    }

    if (
      batchedMedia &&
      audioFile &&
      batchedMediaContext &&
      widgetId === batchedMediaContext.widgetId
    ) {
      if (batchedMediaContext.mediaId === audioId) {
        switch (batchedMediaContext.action) {
          case MediaContextAction.Play:
            if (this.isNewAudio) {
              await changeActiveAudio(
                {...audioFile, length: audioFile.length},
                batchedMediaContext
              );
            }

            if (role === 'student') {
              changeTimestamp(batchedMediaContext.timestamp!);

              if (batchedMediaContext.playbackRate) {
                changePlaybackRate(batchedMediaContext.playbackRate);
              }
            }

            if (playStatus !== PLAYING) {
              changePlayStatus(PLAYING, this.isNewAudio);
            }
            break;

          case MediaContextAction.Pause:
            if (playStatus === undefined) {
              await changeActiveAudio(
                {...audioFile, length: audioFile.length},
                {...batchedMediaContext, action: MediaContextAction.Play}
              );
            }

            if (playStatus !== PAUSE) {
              changePlayStatus(PAUSE);
            }
            break;

          case MediaContextAction.Stop:
            changePlayStatus();
            break;

          case MediaContextAction.ChangeRate:
            changePlaybackRate(batchedMediaContext.playbackRate!);
            break;

          case MediaContextAction.ChangeTimestamp:
            if (this.isNewAudio) {
              await changeActiveAudio(
                {...audioFile, length: audioFile.length},
                batchedMediaContext
              );
            }
            changePlayStatus(batchedMediaContext?.playStatus);
            changeTimestamp(batchedMediaContext.timestamp!);
            break;
        }

        if (role === 'student' && widgetId && batchedMediaContext.shouldScroll) {
          AudioWidgetPlayer.scrollToWidget(widgetId);
        }
      }

      batchAudio();
    }
  }

  public componentDidMount(): void {
    const {audioFile} = this.props;
    if (!audioFile) {
      this.sendFileRequest();
    }

    emitter.addListener(xAudioCurrentTimeChanged, this.setCurrentTime);

    document.addEventListener('keydown', this.keydownEventListener);
  }

  public componentWillUnmount() {
    if (this.props.playStatus) {
      this.props.changePlayStatus();
    }
    if (this.fileRequest) {
      this.fileRequest.cancel();
    }

    emitter.removeListener(xAudioCurrentTimeChanged, this.setCurrentTime);
  }

  public render() {
    const {style, audioFile} = this.props;
    const hasError = this.state.loadFileError;

    if (isMobileWebView()) return this.renderMobileWebView(audioFile);

    return audioFile && !hasError ? (
      this.renderMainView(audioFile, hasError)
    ) : (
      <div className={this.getClasses(hasError)} style={style}>
        {hasError ? this.renderErrorView() : this.renderLoadingFileView()}
      </div>
    );
  }

  private keydownEventListener = (event: KeyboardEvent) => {
    if (isHotkey('arrowleft', event)) {
      this.changeTimestamp(Math.max(this.audioCurrentTime - this.forwardBackwardTime, 0));
    }

    if (isHotkey('arrowright', event)) {
      this.changeTimestamp(this.audioCurrentTime + this.forwardBackwardTime);
    }
  };

  private sendFileRequest = () => {
    const {requestAudioFile, audioId} = this.props;
    if (this.state.loadFileError) {
      this.setState({loadFileError: false});
    }
    this.fileRequest = makeCancellable(
      requestAudioFile(audioId),
      this.handleFileLoaded,
      this.handleError
    );
  };

  private renderErrorView = () => {
    return (
      <div className="player-error">
        <Icon name="warning" size="xlg" />
        <FormattedMessage id="Media.Audio.Error" />
        <Button bsStyle="primary" bsSize="sm" onClick={this.sendFileRequest}>
          <FormattedMessage id="Common.Retry" />
        </Button>
      </div>
    );
  };

  private renderMobileWebView = (audioFile?: AudioFile) => {
    return <MobileAudioWidgetPlayer audioFile={audioFile} />;
  };

  private renderMainView = (audioFile: AudioFile, hasError?: boolean) => {
    const {preview, usePortal, isModal} = this.props;
    const {View} = this;
    const portalId = isModal ? xPlayerPortalWidgetPreview : undefined;

    return usePortal ? (
      <PlayerPortalWrapper
        getPlayerNode={this.getThis}
        preview={preview}
        placeholder={<div className="exercise-audio-widget-player--placeholder" />}
        isActive={this.isActive}
        containerId={portalId}
      >
        {inPortal => (
          <Focusable>
            <View
              audioFile={audioFile}
              hasError={hasError}
              inPortal={inPortal}
              isTogether={this.state.isTogether}
            />
          </Focusable>
        )}
      </PlayerPortalWrapper>
    ) : (
      <Focusable>
        <View audioFile={audioFile} hasError={hasError} isTogether={this.state.isTogether} />
      </Focusable>
    );
  };

  private View: FC<{
    audioFile: AudioFile;
    hasError?: boolean;
    inPortal?: boolean;
    isTogether: boolean;
  }> = ({audioFile, hasError, inPortal}) => {
    const {
      activeAudioFile,
      children,
      compact,
      dark,
      isMobile,
      parentContainerClass,
      style,
      preview,
      playbackRate,
      changePlayStatus,
      withTogetherButton
    } = this.props;
    const {PPButton} = this;

    const isFocused = useFocused();

    useEffect(() => {
      if (!isFocused) return;

      document.addEventListener('keydown', this.keydownEventListener);

      return () => {
        document.removeEventListener('keydown', this.keydownEventListener);
      };
    }, [isFocused]);

    return (
      <div className={this.getClasses(hasError)} style={style}>
        <div className="controls">
          <PPButton isTogether={this.state.isTogether} />
          <Button
            onClick={this.stop}
            disabled={!this.isActive || !activeAudioFile}
            className={classNames('stop', {
              together: this.isActive && activeAudioFile && this.state.isTogether
            })}
          >
            <Icon name="stop" />
          </Button>
          {!isMobile && !AudioWidgetPlayer.isIos && (
            <VolumeControl
              inPortal={inPortal}
              parentContainerClass={parentContainerClass}
              disabled={!this.isActive && !!activeAudioFile}
            />
          )}
          <PlaybackRate
            getTooltipContainer={inPortal ? this.getTooltipContainer : undefined}
            buttonClassName="icon-button"
            playbackRate={playbackRate}
            changePlaybackRate={this.changePlaybackRate}
          />
        </div>
        <div className="slider-group">
          {!compact && (
            <div className="file-meta-group">
              <span className="title" title={audioFile.title}>
                {audioFile.title}
              </span>
              {this.renderSoundDurationAndSpinner(audioFile)}
            </div>
          )}
          <AudioSlider
            dark={dark}
            className={this.sliderClasses}
            isActive={this.isActive}
            isTogether={this.state.isTogether}
            renderPlaceholder={true}
            changePlayStatus={changePlayStatus}
            changeTimestamp={this.changeTimestamp}
            initTimestamp={this.audioCurrentTime}
          />
          {compact && this.renderSoundDurationAndSpinner(audioFile)}
        </div>
        <div className="additional-controls">
          {withTogetherButton && (
            <PlayTogether
              preview={preview}
              active={this.state.isTogether}
              onClick={this.onTogetherButtonClick}
              reset={this.resetIsTogether}
            />
          )}

          {children}
        </div>
      </div>
    );
  };

  private onTogetherButtonClick = () => {
    this.setState(
      prevState => ({...prevState, isTogether: !prevState.isTogether}),
      () => {
        if (this.state.isTogether) {
          this.playTogether();
        }
      }
    );
  };

  private resetIsTogether = () => {
    this.setState(prevState => ({...prevState, isTogether: false}));
  };

  private getTooltipContainer = () => document.querySelector('body')!;

  private renderLoadingFileView = () => {
    return (
      <div className="loading-file">
        <Icon name="virc-audio" size="xlg" />
        <span className="loading-label">
          <FormattedMessage id="Media.Audio.Loading" />
        </span>
        <Spinner size={18} />
      </div>
    );
  };

  private getThis = () => findDOMNode(this) as HTMLDivElement | null;

  private handleFileLoaded = (action: AxiosResponseAction<AudioFile>) => {
    this.props.setLoadedFile(action.payload.data);
  };

  private handleError = () => this.setState({loadFileError: true});

  private play = () => {
    this.playAction();
  };

  private playTogether = () => {
    this.playAction(true);
  };

  private playAction = (forceScroll = false) => {
    if (this.state.isTogether) {
      return this.actionTogether(MediaContextAction.Play, {
        timestamp: this.isNewAudio ? 0 : this.audioCurrentTime,
        playbackRate: this.props.playbackRate,
        shouldScroll: forceScroll
      });
    }

    this.playAudio();
  };

  playAudio() {
    if (this.isNewAudio) {
      // we sure audio file is specified bc otherwise play button wouldn't render
      this.props.changeActiveAudio(this.props.audioFile!, this.mediaContext);
    }
    this.props.changePlayStatus(PLAYING);
  }

  private pause = (e: React.SyntheticEvent<Button>) => {
    e.preventDefault();
    e.stopPropagation();

    if (this.state.isTogether) {
      return this.actionTogether(MediaContextAction.Pause);
    }

    this.props.changePlayStatus(PAUSE);
  };

  private stop = (e?: React.SyntheticEvent<Button>) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (this.state.isTogether) {
      return this.actionTogether(MediaContextAction.Stop);
    }

    this.isActive && this.props.changePlayStatus();
  };

  private changePlaybackRate = (playbackRate: number) => {
    if (this.state.isTogether) {
      return this.actionTogether(MediaContextAction.ChangeRate, {playbackRate});
    }

    this.props.changePlaybackRate(playbackRate);
  };

  private changeTimestamp = (timestamp: number) => {
    if (this.state.isTogether) {
      return this.actionTogether(MediaContextAction.ChangeTimestamp, {
        timestamp,
        playStatus: this.props.playStatus
      });
    }

    this.props.changeTimestamp(timestamp);
  };

  private actionTogether(action: MediaContextAction, actionData?: Partial<MediaContext>) {
    if (this.actionTogetherTimeout) return;
    const errorMessage = this.props.intl.formatMessage(errorMessages[action]);

    this.actionTogetherTimeout = setTimeout(
      () => this.onErrorTogetherAction(errorMessage),
      openingUrlForStudentTimeout
    );
    this.props.actionTogether({
      id: `${this.props.widgetId}::${this.props.audioId}`,
      mediaType: 'audio',
      mediaContext: {...this.mediaContext!, ...actionData, action}
    });
  }

  private onErrorTogetherAction = (errorMessage: string) => {
    this.clearActionTogetherTimeout();
    this.props.showActionTogetherError(errorMessage);
  };

  private clearActionTogetherTimeout() {
    if (this.actionTogetherTimeout) {
      clearTimeout(this.actionTogetherTimeout);
      this.actionTogetherTimeout = null;
    }
  }

  // PP = Play-Pause
  private PPButton: React.FC<{isTogether: boolean}> = ({isTogether}) => (
    <Button
      onClick={this.ppButton.handler}
      className={classNames(`${this.ppButton.icon} icon-button`, {
        active: this.isActive,
        together: isTogether
      })}
    >
      <Icon name={this.ppButton.icon} />
    </Button>
  );

  private renderSoundDurationAndSpinner = (audioFile: AudioFile) => {
    const {activeAudioFile, playStatus} = this.props;
    let duration = 0;
    if (audioFile.length) {
      duration = audioFile.length;
    } else if (this.isActive && activeAudioFile?.length) {
      duration = activeAudioFile.length;
    }

    return (
      <div className="sound-duration-and-spinner">
        {playStatus === LOADING && this.isActive && <Spinner size={18} />}
        <span className="sound-duration">
          {this.isActive && (
            <TimeDisplay currentTime={this.audioCurrentTime} event={xAudioCurrentTimeChanged} />
          )}
          {duration ? formatSecondsToString(duration) : '00:00'}
        </span>
      </div>
    );
  };
}

export default AudioWidgetPlayer;
