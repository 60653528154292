import React, {type FC, memo} from 'react';
import classNames from 'classnames';

import Icon from 'components/Icon';

import './CornerCheckbox.scss';

interface Props {
  checked: boolean;
  answer?: boolean;
  onChange?: () => void;
  readOnly?: boolean;
  borderCorrect?: boolean;
}

export const CornerCheckbox: FC<Props> = memo(
  ({checked, answer, onChange, readOnly = false, borderCorrect = false}) => {
    const selected = checked && answer === undefined;
    const correct = checked && answer;
    const incorrect = checked && answer === false;
    const wasCorrect = !checked && answer;

    return (
      <div
        className={classNames('corner-checkbox', {
          selected: selected,
          correct: correct || wasCorrect,
          incorrect: incorrect,
          'border-correct': borderCorrect
        })}
      >
        <label className={'corner-checkbox__label'}>
          <input
            className="corner-checkbox__input"
            type="checkbox"
            onChange={onChange}
            readOnly={readOnly}
            checked={checked}
          />

          <Icon
            className="corner-checkbox__icon"
            name={checked ? 'check' : 'square-o'}
            size="xlg"
            tag={'i'}
          />
        </label>
      </div>
    );
  }
);
