import React, {PureComponent} from 'react';
import classNames from 'classnames';
import {findDOMNode} from 'react-dom';

import {type Role} from 'store/interface';
import Icon from 'components/Icon';
import {type PlayStatusV2, PAUSE} from 'components/media/interface';
import PlayTogether from 'components/media/_common/PlayTogether';
import {isMobileWebView} from 'helpers/browser';

import VolumeControl from '../../../../AudioPlayer/views/components/VolumeControl/VolumeControl';
import {PlaybackRate} from '../../../../AudioPlayer/views/components/PlaybackRate';
import './styles.scss';

interface Props {
  id: string;
  isFullscreen: boolean;
  isMobile?: boolean;
  playStatus?: PlayStatusV2;
  playbackRate?: number;
  preview?: boolean;
  role?: Role;
  volume: number;
  isTogether: boolean;
  volumeAndFullscreenDisabled: boolean;
  changePlaybackRate(playbackRate: number): void;
  changeVolume(volume: number): void;
  stop(): void;
  showControls(): void;
  toggleFullscreen(): void;
  toggleControlState(newState: boolean): void;
  triggerPlayPause(): void;
  onVolumePopup(volumePoppedUp: boolean): void;
  onPlaybackRatePopup(poppedUp?: boolean): void;
  toggleIsTogether(e: React.SyntheticEvent<HTMLButtonElement>): void;
  resetIsTogether(): void;
}

class Controls extends PureComponent<Props> {
  private get ppClasses() {
    const {playStatus} = this.props;
    return classNames('btn btn-ico', {
      pause: playStatus === PAUSE,
      active: !!playStatus,
      together: this.props.isTogether
    });
  }

  public render() {
    const {
      children,
      isFullscreen,
      isMobile,
      changeVolume,
      changePlaybackRate,
      playStatus,
      playbackRate,
      stop,
      role,
      preview,
      triggerPlayPause,
      onVolumePopup,
      onPlaybackRatePopup,
      volume,
      isTogether,
      toggleIsTogether,
      resetIsTogether,
      volumeAndFullscreenDisabled
    } = this.props;
    const shouldShowFullscreenButton = !isMobileWebView();
    return (
      <div className="video-controls" onBlur={this.onControlBlur} onFocus={this.onControlFocus}>
        <div className="playback-buttons">
          <button className={this.ppClasses} onClick={triggerPlayPause}>
            <Icon name={`${!playStatus || playStatus === PAUSE ? 'play' : 'pause'}`} />
          </button>
          <button
            className={classNames('btn btn-ico', {together: this.props.isTogether})}
            onClick={stop}
            disabled={!playStatus}
          >
            <Icon name="stop" />
          </button>
          {isMobile ? null : (
            <VolumeControl
              changeVolume={changeVolume}
              disabled={volumeAndFullscreenDisabled}
              isFullscreen={isFullscreen}
              onPopupCallback={onVolumePopup}
              placement="top"
              volume={volume}
            />
          )}
          <PlaybackRate
            getTooltipContainer={this.getPlaybackRateContainer}
            buttonClassName="btn-ico btn"
            playbackRate={playbackRate}
            placement="top"
            changePlaybackRate={changePlaybackRate}
            onVisibleChangeCallback={onPlaybackRatePopup}
          />
        </div>
        {children}
        <div className="options">
          {role === 'teacher' && (
            <PlayTogether
              className="btn-ico"
              active={isTogether}
              preview={preview}
              onClick={toggleIsTogether}
              reset={resetIsTogether}
            />
          )}
          {shouldShowFullscreenButton && (
            <button
              className="btn btn-ico btn-fullscreen"
              onClick={this.toggleFullscreen}
              disabled={volumeAndFullscreenDisabled}
            >
              <Icon name={`virc-${isFullscreen ? '' : 'no_'}clutter`} />
            </button>
          )}
        </div>
      </div>
    );
  }

  private getPlaybackRateContainer = () =>
    this.props.isFullscreen
      ? (findDOMNode(this) as HTMLElement)
      : (document.querySelector('.x-player-canvas') as HTMLElement) ||
        (document.querySelector('.xeditor-canvas') as HTMLElement);

  private onControlFocus = () => {
    this.props.showControls();
    this.props.toggleControlState(true);
  };

  private onControlBlur = () => this.props.toggleControlState(false);

  private toggleFullscreen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.blur();
    this.props.toggleFullscreen();
  };
}

export default Controls;
