import {type Editor, type Plugin, type RenderBlockProps} from '@englex/slate-react';
import React from 'react';
import {type Next} from '@englex/slate';

import {isBlockOfType} from '../../../utils';
import {SlateBlock} from '../../../interface';

export default class QuestionsList implements Plugin {
  public renderBlock = (
    {node, attributes, children}: RenderBlockProps,
    editor: Editor,
    next: Next
  ) => {
    if (isBlockOfType(node, SlateBlock.QUESTION_LIST) || isBlockOfType(node, SlateBlock.LIST)) {
      return (
        <ol className="list list-ol list-ol-0-0" {...attributes}>
          {children}
        </ol>
      );
    }

    return next();
  };
}
