import React from 'react';
import './Dot.scss';

interface Props {
  show?: boolean;
}

const Dot = ({show}: Props) => (show ? <span className="x-player-dot" /> : null);

export default Dot;
