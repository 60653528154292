import {connect} from 'react-redux';
import {type Action, type Dispatch} from 'redux';

import {type AppState} from 'store/interface';
import {changeVolume} from 'store/media/audio/actions';

import VolumeControl from '../../views/components/VolumeControl/VolumeControl';

interface StateProps {
  volume?: number;
}

interface DispatchProps {
  changeVolume: (volume: number) => void;
}

export interface Props extends StateProps, DispatchProps {}

const mapStateToProps = ({media}: AppState): StateProps => ({
  volume: media && media.audio && media.audio.volume
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => ({
  changeVolume: (volume: number) => dispatch(changeVolume(volume))
});

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(VolumeControl);
