import React from 'react';
import {FormattedMessage} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';

import Icon from '../../Icon';
import Spinner from '../../Spinner';
import './VideoLoaderView.scss';

interface Props {
  hasError?: boolean;
  reload: () => void;
}

export default class VideoLoaderView extends React.Component<Props> {
  public render() {
    if (this.props.hasError) {
      return (
        <div className="video-loader-view">
          <Icon name="warning" />
          <FormattedMessage id="Media.Video.Error" />
          <Button bsSize="sm" bsStyle="primary" onClick={this.props.reload}>
            <FormattedMessage id="Common.Retry" />
          </Button>
        </div>
      );
    }
    return (
      <div className="video-loader-view">
        <Icon name="film" />
        <span>
          <FormattedMessage id="Media.Video.Loading" />
          <Spinner size={16} />
        </span>
      </div>
    );
  }
}
