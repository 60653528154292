import React from 'react';
import classNames from 'classnames';

import avatarDefault from './avatar-default.svg';
import avatarHover from './avatar-hover.svg';
import avatarActive from './avatar-active.svg';

export enum AvatarTypes {
  DEFAULT = 'default',
  HOVER = 'hover',
  ACTIVE = 'active'
}

interface Props {
  avatarType: AvatarTypes;
}

const avatars = {
  default: avatarDefault,
  hover: avatarHover,
  active: avatarActive
};

export const AvatarImage: React.FC<Props> = ({avatarType}) => (
  <img
    className={classNames({'avatar-hover': avatarType === AvatarTypes.HOVER})}
    src={avatars[avatarType]}
    alt="avatar"
    width="100%"
    height="100%"
    draggable={false}
  />
);
