import React from 'react';
import {
  type Editor,
  type EditorProps,
  type Plugin,
  type RenderAnnotationProps
} from '@englex/slate-react';
import {type Annotation, type Next, type Node} from '@englex/slate';

import {type WidgetComponentProps} from 'store/exercise/player/interface';
import {pointerAnimationTimeout} from 'config/static';

import {getEditorId, getWidgetProps, SlateAnnotation} from '../../../interface';
import {Listener} from '../../../../Pointer/selection/SelectionListener';
import {SelectionTooltip} from '../../../../Pointer/selection/SelectionTooltip';
import {SelectionPointerEditor} from './SelectionPointerEditor';

interface Props {
  id?: string | number;
  skipContentHashing?: boolean;
  isAvailable?: () => boolean;
  onSelected?: (callback: () => void) => void;
  animationTimeout?: number;
}

export class SelectionPointer implements Plugin {
  private readonly id?: string | number;

  private readonly skipContentHashing?: boolean;

  private readonly animationTimeout: number;

  public isAvailable: () => boolean;

  public onSelected?: (callback: Function) => void;

  private static isAvailable() {
    return true;
  }

  constructor({
    id,
    skipContentHashing,
    isAvailable,
    onSelected,
    animationTimeout = pointerAnimationTimeout
  }: Props = {}) {
    this.id = id;
    this.skipContentHashing = skipContentHashing;
    this.isAvailable = isAvailable || SelectionPointer.isAvailable;
    this.onSelected = onSelected;
    this.animationTimeout = animationTimeout;
  }

  private getTooltipContainer = (editor: Editor, node: Node) => () => {
    const closestBlock = editor.value.document.getClosestBlock(node.key);

    if (closestBlock) {
      const path = editor.value.document.getPath(closestBlock.key);

      if (path) {
        return editor.findDOMNode(path) as HTMLElement;
      }
    }

    return document.documentElement;
  };

  private removeAnnotation = (editor: Editor, annotation: Annotation) => () => {
    setTimeout(() => {
      editor.removeAnnotation(annotation);

      const id = editor.query<string>(getEditorId);

      if (document.getElementById(id)) {
        editor.command(
          SelectionPointerEditor.resetRangeBySelection,
          document.getSelection(),
          false
        );
      }
    }, this.animationTimeout);
  };

  private isStartAnnotationNode = (editor: Editor, node: Node, type: SlateAnnotation): boolean => {
    const annotation = editor.value.annotations.find((a: Annotation) => a.type === type);
    return annotation?.start?.key === node.key && Boolean(node.text);
  };

  private isFocusAnnotationNode = (editor: Editor, node: Node, type: SlateAnnotation): boolean => {
    const annotation = editor.value.annotations.find((a: Annotation) => a.type === type);
    return annotation?.focus?.key === node.key;
  };

  public renderAnnotation = (props: RenderAnnotationProps, editor: Editor, next: Next) => {
    const {children, annotation, node, attributes, offset, text} = props;
    if (
      ![SlateAnnotation.CREATE_POINTER, SlateAnnotation.POINTER].includes(
        annotation.type as SlateAnnotation
      )
    ) {
      return next();
    }

    if (Boolean(node.text) && !text) {
      return next();
    }

    const {exerciseId} = editor.query<WidgetComponentProps>(getWidgetProps) || {};

    if (import.meta.env.MODE === 'development' && !exerciseId) {
      // eslint-disable-next-line no-console
      console.warn(`SelectionListener: exerciseId = ${exerciseId} in renderAnnotation`);
    }

    if (annotation.type === SlateAnnotation.CREATE_POINTER && exerciseId) {
      const isShowTooltip = this.isFocusAnnotationNode(editor, node, annotation.type);
      const getTooltipContainer = isShowTooltip
        ? this.getTooltipContainer(editor, node)
        : () => null!;
      return (
        <span key={annotation.key} className="slate-create-pointer selection">
          {isShowTooltip ? (
            <SelectionTooltip exerciseId={exerciseId} getTooltipContainer={getTooltipContainer}>
              <span {...attributes}>{children}</span>
            </SelectionTooltip>
          ) : (
            <span {...attributes}>{children}</span>
          )}
        </span>
      );
    }

    if (annotation.type === SlateAnnotation.POINTER) {
      const wasAllSelected =
        annotation.start.offset === offset && offset + text.length === annotation.end.offset;
      const isStartAnnotationNode = this.isStartAnnotationNode(editor, node, annotation.type);
      const id = wasAllSelected || isStartAnnotationNode ? annotation.key : undefined;

      return wasAllSelected ? (
        <span
          id={id}
          key={annotation.key}
          onAnimationStart={this.removeAnnotation(editor, annotation)}
          className="slate-pointer"
        >
          <span {...attributes}>{children}</span>
        </span>
      ) : (
        <span {...attributes}>{children}</span>
      );
    }

    return next();
  };

  public renderEditor = (props: EditorProps, editor: Editor, next: Next) => {
    return (
      <Listener
        id={this.id}
        skipContentHashing={this.skipContentHashing}
        isAvailable={this.isAvailable}
        onSelected={this.onSelected}
        editor={editor}
      >
        {next()}
      </Listener>
    );
  };
}
