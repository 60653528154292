import React, {Component, type CSSProperties, type FC} from 'react';
import classNames from 'classnames';
import {Portal} from 'react-portal';
import isHotkey from 'is-hotkey';
import Button from 'react-bootstrap/lib/Button';

import Throttle from 'common/Throttle';
import {xAudioBufferChanged, xAudioCurrentTimeChanged, emitter} from 'services/event-emitter';
import Icon from 'components/Icon';

import {type Props as ContainerProps} from '../../../containers/Exercise/AudioSlider';
import {getPageX, handleMove, handleProgressClick, MoTEventPreHandler} from '../../../../utils';
import {type AudioFileWithLength, type ReactMoTEvent, PAUSE, PLAYING} from '../../../../interface';

import './AudioWidgetSlider.scss';

interface Props extends ContainerProps {
  className?: string;
  dark?: true;
  isActive?: boolean;
  renderPlaceholder?: boolean;
  isTogether?: boolean;
  initTimestamp: number;
}

interface SliderProps extends Omit<Props, 'isActive' | 'activeAudioFile' | 'renderPlaceholder'> {
  activeAudioFile: AudioFileWithLength;
}

interface State {
  buffered: number;
  currentTime: number;
  mouseDown: boolean;
}

const AudioWidgetSlider: FC<Props> = ({isActive, renderPlaceholder, ...rest}) => {
  const {className, dark} = rest;
  if (!rest.activeAudioFile || !rest.activeAudioFile.length || !isActive) {
    return renderPlaceholder ? (
      <div
        className={`progress-placeholder ${classNames({
          dark,
          [className as string]: !!className
        })}`}
      />
    ) : null;
  }
  return <Slider {...(rest as SliderProps)} />;
};

class Slider extends Component<SliderProps, State> {
  private static percentage: number = 100;

  private progress: HTMLDivElement;
  private schedulePlay: boolean;
  private readonly throttle = new Throttle();

  constructor(props: SliderProps) {
    super(props);

    this.state = {
      buffered: 0,
      mouseDown: false,
      currentTime: props.initTimestamp
    };
  }

  private get classes() {
    return classNames('progress', {
      [this.props.className as string]: !!this.props.className,
      dark: this.props.dark
    });
  }

  public componentDidMount() {
    emitter
      .addListener(xAudioBufferChanged, this.receiveBufferedTime)
      .addListener(xAudioCurrentTimeChanged, this.receiveTime);
  }

  public componentWillUnmount() {
    emitter
      .removeListener(xAudioBufferChanged, this.receiveBufferedTime)
      .removeListener(xAudioCurrentTimeChanged, this.receiveTime);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    document.removeEventListener('mousemove', this.moveHandler as any);
    document.removeEventListener('mouseup', this.mouseUpHandler);
  }

  public render() {
    const {activeAudioFile, isMobile, isTogether} = this.props;
    const {currentTime, buffered, mouseDown} = this.state;
    const {ScrollHelper} = this;
    const width = (currentTime / activeAudioFile.length) * Slider.percentage;
    const style: CSSProperties =
      width < 0.8
        ? {width: width + '%', borderTopRightRadius: 0, borderBottomRightRadius: 0}
        : {width: width + '%'};
    return (
      <>
        <div className="controls">
          <Button className="btn-backward" onClick={this.backward}>
            <Icon name="backward" />
          </Button>
          <Button className="btn-forward" onClick={this.forward}>
            <Icon name="forward" />
          </Button>
        </div>
        <div
          className={this.classes}
          ref={this.getRef}
          tabIndex={0}
          onKeyDown={this.keyDownHandler}
          onClick={this.progressClickHandler}
          onTouchStart={this.touchStartHandler}
          onTouchMove={this.moveHandler}
          onTouchEnd={this.pointerUpHandler}
        >
          <div className="progress-amount" style={style}>
            <div
              className={classNames('progress-drag-circle', {
                'mouse-down': mouseDown,
                'is-mobile': isMobile,
                together: isTogether
              })}
              onMouseDown={isMobile ? undefined : this.circlePointerDownHandler}
            />
          </div>
          <div className="buffered-amount" style={{width: buffered * Slider.percentage + '%'}} />
          {mouseDown ? (
            <Portal node={document.querySelector('body')}>
              <ScrollHelper />
            </Portal>
          ) : (
            <ScrollHelper />
          )}
        </div>
      </>
    );
  }

  private backward = () => {
    const {currentTime} = this.state;
    this.setValue(currentTime - 5);
  };

  private forward = () => {
    const {activeAudioFile, changePlayStatus} = this.props;
    const {currentTime} = this.state;
    const newTime = currentTime + 5;
    newTime >= activeAudioFile.length ? changePlayStatus() : this.setValue(newTime);
  };

  private getRef = (progress: HTMLDivElement) => (this.progress = progress);

  private formatValue = (value: number, length: number) => {
    return parseFloat((value > length ? length : value < 0 ? 0 : value).toFixed(6));
  };

  private setValue = (value: number) => {
    const {
      activeAudioFile: {length},
      changeTimestamp
    } = this.props;
    changeTimestamp(this.formatValue(value, length));
  };

  private circlePointerDownHandler = (e: ReactMoTEvent) => {
    const {isTouchEvent} = MoTEventPreHandler(e);
    if (!isTouchEvent) {
      this.setState({mouseDown: true});
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      document.addEventListener('mousemove', this.moveHandler as any);
      document.addEventListener('mouseup', this.mouseUpHandler);
    }
  };

  private keyDownHandler = (event: React.KeyboardEvent<HTMLDivElement> | KeyboardEvent) => {
    const {changePlayStatus, playStatus} = this.props;
    const e = event as KeyboardEvent;
    if (isHotkey('arrowright', e)) {
      e.preventDefault();
      this.forward();
    }
    if (isHotkey('arrowleft', e)) {
      e.preventDefault();
      this.backward();
    }
    if (isHotkey(['enter', ' '], e)) {
      e.preventDefault();
      if (playStatus === PLAYING) {
        changePlayStatus(PAUSE);
        return;
      }
      if (playStatus === PAUSE) changePlayStatus(PLAYING);
    }
  };

  private mouseUpHandler = () => {
    this.pointerUpHandler();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    document.removeEventListener('mousemove', this.moveHandler as any);
    document.removeEventListener('mouseup', this.mouseUpHandler);
    this.setState({mouseDown: false});
    this.setValue(this.state.currentTime);
  };

  private moveHandler = (e: ReactMoTEvent) => {
    const {pageX} = MoTEventPreHandler(e, true);
    const {changePlayStatus, playStatus} = this.props;
    if (!this.schedulePlay && playStatus !== PAUSE) {
      this.schedulePlay = true;
      changePlayStatus(PAUSE);
    }
    this.throttle.throttleAction(() =>
      handleMove(pageX, this.props.activeAudioFile.length, this.progress, this.setValue)
    );
  };

  private pointerUpHandler = () => {
    if (this.schedulePlay) {
      this.props.changePlayStatus(PLAYING);
      this.schedulePlay = false;
      this.setValue(this.state.currentTime);
    }
  };

  private progressClickHandler = (e: React.MouseEvent<HTMLDivElement>) =>
    handleProgressClick(e, this.props.activeAudioFile.length, this.progress, this.setValue);

  private touchStartHandler = (e: React.TouchEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const {length} = this.props.activeAudioFile;
    const {left, width} = this.progress.getBoundingClientRect();
    this.setValue(((getPageX(e) - left) / width) * length);
  };

  private receiveBufferedTime = (buffered: number) => {
    if (buffered !== this.state.buffered) this.setState({buffered});
  };

  private receiveTime = (currentTime: number) => {
    if (currentTime !== this.state.currentTime) this.setState({currentTime});
  };

  private ScrollHelper = () => (
    <div
      className={classNames('audio-scroll-helper', {'mouse-down': this.state.mouseDown})}
      onClick={this.progressClickHandler}
      onTouchStart={this.touchStartHandler}
      onTouchMove={this.moveHandler}
      onTouchEnd={this.pointerUpHandler}
    />
  );
}

export default AudioWidgetSlider;
