import React from 'react';
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';

interface Props {
  time: number;
}

export const RewindBubble: React.FC<Props> = ({time}) => {
  return (
    <div className="rewind-bubble">
      <div className={classNames('arrows')}>
        <div className="arrow" />
        <div className="arrow" />
        <div className="arrow" />
      </div>

      <span className="time">
        {time} <FormattedMessage id="Media.Video.Sec" />
      </span>
    </div>
  );
};
