import {type RenderAttributes} from '@englex/slate-react';
import classNames from 'classnames';
import React, {memo} from 'react';

import {type StyledListStyles} from 'store/exercise/player/widgets/List/StyledList/interface';

import './VerticalNumberedItem.scss';

interface Props {
  index: number;
  attributes: RenderAttributes;
  id: string;
  styleName: StyledListStyles;
  twoColumns?: boolean;
  isActive: boolean;
  children: React.ReactNode;
  onClick?: () => void;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
}

export const VerticalNumberedItem: React.FC<Props> = memo(
  ({index, attributes, id, isActive, styleName, twoColumns, children, onClick, onPaste}) => {
    return (
      <div
        {...attributes}
        id={id}
        className={classNames('vertical-numbered-styled-list-item', styleName, {
          'two-columns-item': twoColumns,
          'is-active': isActive
        })}
        onClick={onClick}
      >
        <div className="vertical-numbered-styled-list-item__number">
          <span>{index + 1}</span>
        </div>
        <div className="vertical-numbered-styled-list-item__text" onPaste={onPaste}>
          {children}
        </div>
      </div>
    );
  }
);
