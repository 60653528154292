import React, {Component, type CSSProperties} from 'react';
import classNames from 'classnames';
import {Portal} from 'react-portal';
import isHotkey from 'is-hotkey';

import Throttle from 'common/Throttle';
import {type PlayStatusV2, PLAYING, type ReactMoTEvent, PAUSE} from 'components/media/interface';

import {getPageX, handleMove, handleProgressClick, MoTEventPreHandler} from '../../../../utils';
import './VideoSlider.scss';

interface State {
  mouseDown: boolean;
}

interface Props {
  className: string;
  currentTime: number;
  duration: number;
  isFullscreen: boolean;
  isMobile?: boolean;
  isTogether: boolean;
  playStatus?: PlayStatusV2;
  play(): void;
  pause(): void;
  stop(): void;
  updateTime(time: number): void;
}

export default class VideoSlider extends Component<Props, State> {
  public state: State = {mouseDown: false};

  private progress: HTMLDivElement;
  private schedulePlay: boolean;
  private readonly throttle = new Throttle();

  private get classes() {
    return classNames('progress', {
      [this.props.className]: !!this.props.className,
      together: this.props.isTogether
    });
  }

  public componentWillUnmount() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    document.removeEventListener('mousemove', this.moveHandler as any);
    document.removeEventListener('mouseup', this.mouseUpHandler);
  }

  public render() {
    const {children, currentTime, duration, isFullscreen, isMobile, playStatus} = this.props;
    const {mouseDown} = this.state;
    const {ScrollHelper} = this;

    if (!playStatus) {
      return <div className={this.classes} />;
    }
    const width = (currentTime / duration) * 100;
    const style: CSSProperties =
      width < 0.8
        ? {width: width + '%', borderTopRightRadius: 0, borderBottomRightRadius: 0}
        : {width: width + '%'};
    return (
      <div
        className={this.classes}
        ref={this.getRef}
        tabIndex={0}
        onKeyDown={this.keyDownHandler}
        onClick={this.progressClickHandler}
        onTouchStart={this.touchStartHandler}
        onTouchMove={this.moveHandler}
        onTouchEnd={this.pointerUpHandler}
      >
        <div className="progress-amount" style={style}>
          <div
            className={classNames('progress-drag-circle', {
              'mouse-down': mouseDown,
              'is-mobile': isMobile
            })}
            onMouseDown={isMobile ? undefined : this.circlePointerDownHandler}
          />
        </div>
        {children}
        {mouseDown && !isFullscreen ? (
          <Portal node={document.querySelector('body')}>
            <ScrollHelper />
          </Portal>
        ) : (
          <ScrollHelper />
        )}
      </div>
    );
  }

  private getRef = (progress: HTMLDivElement) => (this.progress = progress);

  private setValue = (value: number) => {
    const {duration, updateTime} = this.props;
    updateTime(parseFloat((value > duration ? duration : value < 0 ? 0 : value).toFixed(6)));
  };

  private circlePointerDownHandler = (e: ReactMoTEvent) => {
    const {isTouchEvent} = MoTEventPreHandler(e);
    if (!isTouchEvent) {
      this.setState({mouseDown: true});
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      document.addEventListener('mousemove', this.moveHandler as any);
      document.addEventListener('mouseup', this.mouseUpHandler);
    }
  };

  private keyDownHandler = (event: React.KeyboardEvent<HTMLDivElement> | KeyboardEvent) => {
    const {pause, play, playStatus} = this.props;
    const e = event as KeyboardEvent;
    if (isHotkey(['enter', ' '], e)) {
      e.preventDefault();
      playStatus === PLAYING ? pause() : play();
    }
  };

  private mouseUpHandler = () => {
    this.pointerUpHandler();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    document.removeEventListener('mousemove', this.moveHandler as any);
    document.removeEventListener('mouseup', this.mouseUpHandler);
    this.setState({mouseDown: false});
  };

  private moveHandler = (e: ReactMoTEvent) => {
    const {pageX} = MoTEventPreHandler(e, true);
    const {pause, playStatus} = this.props;
    if (!this.schedulePlay && playStatus !== PAUSE) {
      this.schedulePlay = true;
      pause();
    }
    this.throttle.throttleAction(() =>
      handleMove(pageX, this.props.duration, this.progress, this.setValue)
    );
  };

  private pointerUpHandler = () => {
    if (this.schedulePlay) {
      this.props.play();
      this.schedulePlay = false;
    }
  };

  private progressClickHandler = (e: React.MouseEvent<HTMLDivElement>) =>
    handleProgressClick(e, this.props.duration, this.progress, this.setValue);

  private touchStartHandler = (e: React.TouchEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const {duration} = this.props;
    const {left, width} = this.progress.getBoundingClientRect();
    this.setValue(((getPageX(e) - left) / width) * duration);
  };

  private ScrollHelper = () => (
    <div
      className={classNames('video-scroll-helper', {
        'mouse-down': this.state.mouseDown,
        fullscreen: this.props.isFullscreen
      })}
      onClick={this.progressClickHandler}
      onTouchStart={this.touchStartHandler}
      onTouchMove={this.moveHandler}
      onTouchEnd={this.pointerUpHandler}
    />
  );
}
