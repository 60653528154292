import React, {useContext} from 'react';
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';
import Spinner from 'react-spinkit';

import {type AudioFile} from '../../interface';
import {WebviewPlayerContext} from '../../../../contexts/Webview/WebviewPlayerContext';
import {
  useWebViewMessage,
  PlayerPlayStatus,
  WebViewMessageType
} from '../../../../hooks/webview/useWebViewMessage';
import {formatSecondsToString} from '../../../../services/common-methods';
import Icon from '../../../Icon';

import './MobileAudioWidgetPlayer.scss';

function getIcon(playStatus?: PlayerPlayStatus) {
  switch (playStatus) {
    case PlayerPlayStatus.Playing:
      return 'pause';

    default:
      return 'play';
  }
}

interface Props {
  audioFile?: AudioFile;
}

export const MobileAudioWidgetPlayer: React.FC<Props> = ({audioFile}) => {
  const {audio, playStatus} = useContext(WebviewPlayerContext);

  const postMessage = useWebViewMessage();

  const active = audioFile && audio?.id === audioFile.id;

  const status = active ? playStatus : undefined;

  const icon = getIcon(status);

  const onClick = () => {
    if (!active)
      return postMessage({
        type: WebViewMessageType.Player,
        payload: {audio: audioFile}
      });

    switch (status) {
      case PlayerPlayStatus.Playing:
        return postMessage({
          type: WebViewMessageType.Player,
          payload: {playStatus: PlayerPlayStatus.Paused}
        });

      case PlayerPlayStatus.Paused:
        return postMessage({
          type: WebViewMessageType.Player,
          payload: {playStatus: PlayerPlayStatus.Playing}
        });

      default:
        return undefined;
    }
  };

  if (audioFile) {
    return (
      <div className={classNames('mobile-audio-widget', {active})} onClick={onClick}>
        <div className="mobile-audio-widget__button">
          <Icon name={icon} />
        </div>
        <div className="mobile-audio-widget__info">
          <div className="audio-file-title">{audioFile.title}</div>
          <div className="audio-file-meta">
            <div>{formatSecondsToString(audioFile.length || 0)}</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames('mobile-audio-widget')}>
      <div className="mobile-audio-widget__spinner">
        <Spinner name="circle" color="#f65a55" />
      </div>
      <div className="mobile-audio-widget__info">
        <span className="loading-label">
          <FormattedMessage id="Media.Audio.Loading" />
        </span>
      </div>
    </div>
  );
};
