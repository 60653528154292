import {type Action} from 'redux';

import {type AxiosRequestAction} from 'services/axios/interface';

import {type ChangeVolumeAction, type SetDurationAction} from '../interface';
import {
  ACTIVATE_PLAYER,
  CHANGE_PLAYBACK_RATE,
  CHANGE_VOLUME,
  DEACTIVATE_PLAYER,
  REGISTER_PLAYER,
  REQUEST_VIDEO_PROPS,
  SET_DURATION,
  UNREGISTER_PLAYER
} from './actionTypes';

export interface VideoAction extends Action {
  playerId: string;
}

export interface RegisterVideoPlayerAction extends VideoAction {
  sourceId: string;
  url?: string;
  posterUrls?: string[];
}

export interface SetVideoDurationAction extends SetDurationAction, VideoAction {}

export const registerVideoPlayer = (
  playerId: string,
  sourceId: string,
  url?: string,
  posterUrls?: string[]
): RegisterVideoPlayerAction => ({
  type: REGISTER_PLAYER,
  playerId,
  sourceId,
  url,
  posterUrls
});

export const unregisterVideoPlayer = (playerId: string): VideoAction => ({
  type: UNREGISTER_PLAYER,
  playerId
});

export const activatePlayer = (playerId: string): VideoAction => ({
  type: ACTIVATE_PLAYER,
  playerId
});
export const deactivatePlayer = (playerId: string): VideoAction => ({
  type: DEACTIVATE_PLAYER,
  playerId
});

export const changeVolume = (volume: number): ChangeVolumeAction => ({
  type: CHANGE_VOLUME,
  volume
});

export const setDuration = (playerId: string, length: number): SetVideoDurationAction => ({
  type: SET_DURATION,
  playerId,
  length
});

export const requestVideoFile: (id: number) => AxiosRequestAction = id => ({
  type: REQUEST_VIDEO_PROPS,
  payload: {
    client: 'v2',
    request: {
      method: 'GET',
      url: `/v2/video/${id}`
    }
  }
});

export interface ChangePlaybackRateAction extends Action {
  playbackRate: number;
}
export const changePlaybackRate = (playbackRate: number): ChangePlaybackRateAction => ({
  type: CHANGE_PLAYBACK_RATE,
  playbackRate
});
