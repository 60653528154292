import React, {createContext, type FC, useCallback, useContext, useEffect, useState} from 'react';

export interface DndContextShape {
  selectedCardId?: string;
  currentHoverElementId?: string;
  selectCard(id?: string, e?: React.MouseEvent<HTMLDivElement>): void;
  setCurrentHoverElementId?: (currentHoverElementId: string) => void;
}

interface ProviderProps {
  isModal?: boolean;
  children?: React.ReactNode;
}

const DndContext = createContext<DndContextShape>({} as DndContextShape);

const Provider: FC<ProviderProps> = ({isModal, children}) => {
  const [selectedCardId, setSelectedCardId] = useState<string | undefined>();
  const [currentHoverElementId, setCurrentHoverElementId] = useState('');

  const clearSelected = useCallback(() => {
    setSelectedCardId(undefined);
  }, []);

  const selectCard = useCallback(
    (cardId?: string) => {
      if (!cardId || selectedCardId === cardId) clearSelected();
      else setSelectedCardId(cardId);
    },
    [clearSelected, selectedCardId]
  );

  useEffect(() => {
    if (selectedCardId) {
      if (isModal) document.addEventListener('click', clearSelected);
      else document.getElementById('root')?.addEventListener('click', clearSelected);
    }
    return () => {
      if (isModal) document.removeEventListener('click', clearSelected);
      else document.getElementById('root')?.removeEventListener('click', clearSelected);
    };
  }, [clearSelected, isModal, selectedCardId]);

  return (
    <DndContext.Provider
      value={{selectedCardId, currentHoverElementId, selectCard, setCurrentHoverElementId}}
    >
      {children}
    </DndContext.Provider>
  );
};

const useDndContext = () => useContext(DndContext);

export {Provider, useDndContext};
