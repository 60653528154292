import {type RenderAttributes} from '@englex/slate-react';
import classNames from 'classnames';
import React, {memo} from 'react';

import {type StyledListStyles} from 'store/exercise/player/widgets/List/StyledList/interface';

import {StarSVG} from '../Star/star';

import './VerticalBulletedItem.scss';

interface Props {
  attributes: RenderAttributes;
  id: string;
  styleName: StyledListStyles;
  twoColumns?: boolean;
  isActive: boolean;
  children: React.ReactNode;
  onClick?: () => void;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
}

export const VerticalBulletedItem: React.FC<Props> = memo(
  ({attributes, id, styleName, twoColumns, isActive, children, onClick, onPaste}) => {
    return (
      <div
        {...attributes}
        id={id}
        className={classNames('vertical-bulleted-styled-list-item', styleName, {
          'two-columns-item': twoColumns,
          'is-active': isActive
        })}
        onClick={onClick}
      >
        <div className="vertical-bulleted-styled-list-item__number">
          <StarSVG styleName={styleName} />
        </div>
        <div className="vertical-bulleted-styled-list-item__text" onPaste={onPaste}>
          {children}
        </div>
      </div>
    );
  }
);
