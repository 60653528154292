import {connect} from 'react-redux';
import {type Dispatch} from 'redux-axios-middleware';
import {type Action} from 'redux';
import {useLocation, useParams} from 'react-router-dom';
import {injectIntl} from 'react-intl';
import {type FC} from 'react';

import * as toastr from 'components/toastr';
import {type AppState} from 'store/interface';
import {batchMedia, urlOpened} from 'common/action';
import {
  changePlaybackRate,
  changePlayStatus,
  changeTimestamp,
  requestAudioFile
} from 'store/media/audio/actions';

import {
  type AudioFile,
  type MediaContext,
  type MediaType,
  type PlayStatusV2
} from '../../../interface';
import AudioWidgetPlayerView from '../../views/AudioWidgetPlayer';
import {
  type AudioWidgetPlayerDispatchProps,
  type AudioWidgetPlayerOwnProps,
  type AudioWidgetPlayerRouteProps,
  type AudioWidgetPlayerStateProps
} from '../../views/interface';
import {actionTogether, changeActiveAudio} from './actions';

const mapStateToProps = ({
  classroom,
  layout,
  media,
  user
}: AppState): AudioWidgetPlayerStateProps => {
  const activeAudioFile = media.audio?.audioFile;
  const batchedMedia = classroom?.batchedMedia;
  const batchedMediaContext = classroom?.batchedMediaContext;
  const playStatus = media.audio?.playStatus;
  const playbackRate = media.audio?.playbackRate;
  const context = media.audio?.context;
  const timestamp = media.audio?.timestamp || 0;

  return {
    role: user.role!,
    activeAudioFile,
    batchedMedia,
    batchedMediaContext,
    isMobile: layout.isMobile,
    playStatus,
    playbackRate,
    context,
    timestamp
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<Action, AppState>,
  {courseInstanceId, pathname}: AudioWidgetPlayerRouteProps
): AudioWidgetPlayerDispatchProps => {
  const courseId = Number(courseInstanceId);
  const url = pathname;

  return {
    batchAudio: () => dispatch(batchMedia()),
    changePlaybackRate: (playbackRate: number) => dispatch(changePlaybackRate(playbackRate)),
    changeActiveAudio: (audioFile: AudioFile, context?: MediaContext) =>
      dispatch(changeActiveAudio(audioFile, context)),
    changePlayStatus: (playStatus: PlayStatusV2, forceRestart?: true) =>
      dispatch(changePlayStatus(playStatus, forceRestart)),
    changeTimestamp: (timestamp: number) => dispatch(changeTimestamp(timestamp)),
    requestAudioFile: (id: number) => dispatch(requestAudioFile(id)),
    actionTogether: (data: {id: string; mediaType: MediaType; mediaContext: MediaContext}) =>
      dispatch(actionTogether({courseId, url, ...data})),
    showActionTogetherError: (errorMessage: string) => {
      dispatch(urlOpened());
      toastr.error('', errorMessage);
    }
  };
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(injectIntl(AudioWidgetPlayerView));

const AudioWidgetPlayer: FC<AudioWidgetPlayerOwnProps> = props => {
  const {courseId} = useParams<{courseId: string}>();
  const {pathname} = useLocation();

  return <Connected {...props} courseInstanceId={courseId!} pathname={pathname} />;
};
export default AudioWidgetPlayer;
