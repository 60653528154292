import React, {useEffect, useContext, useRef} from 'react';
import {type Editor as LegacyEditor} from '@englex/slate-react';
import {type Editor} from 'slate';

import {SelectionContext} from './SelectionContext';
import {SlatePointerFacade} from './SlatePointerFacade';

import './Selection.scss';

interface Props {
  id?: string | number;
  editor: Editor | LegacyEditor;
  skipContentHashing?: boolean;
  isAvailable: () => boolean;
  onSelected?: (callback: Function) => void;
}

export const Listener: React.FC<Props> = ({
  id = '',
  editor,
  skipContentHashing,
  isAvailable,
  onSelected,
  children
}) => {
  const contentHashRef = useRef<string>();

  const {role, selectionData, subscribe, highlightStudentRange} = useContext(SelectionContext);

  useEffect(() => {
    const widgetProps = SlatePointerFacade.getWidgetProps(editor);

    if (widgetProps && widgetProps.isPointerAvailable) {
      contentHashRef.current = skipContentHashing
        ? `${id}`
        : SlatePointerFacade.getContentHash(editor, id);

      const unsubscribe = subscribe(contentHashRef.current, editor, isAvailable);

      return () => unsubscribe();
    }

    if (!widgetProps) {
      // eslint-disable-next-line no-console
      return console.warn(
        'SelectionListener: getWidgetProps is required. Add withWidgetProps plugin to SlateEditor.'
      );
    }
  }, [id, skipContentHashing, isAvailable, editor, subscribe]);

  useEffect(() => {
    if (
      role === 'student' &&
      contentHashRef.current &&
      contentHashRef.current === selectionData?.editorId &&
      selectionData?.range
    ) {
      if (onSelected) {
        return onSelected(() => {
          if (contentHashRef.current) highlightStudentRange(contentHashRef.current, selectionData);
        });
      }

      highlightStudentRange(contentHashRef.current, selectionData);
    }
  }, [highlightStudentRange, onSelected, role, selectionData]);

  return <>{children}</>;
};
