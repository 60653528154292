import {
  type Block,
  type Editor,
  type NodeProperties,
  type SlateError,
  type Next,
  type Query,
  type Rules
} from '@englex/slate';
import {type Editor as ReactEditor, type Plugin} from '@englex/slate-react';
import type React from 'react';

import isShortcut from 'helpers/shortcut';

import {
  type InsertFragmentPlugin,
  type Normalizer,
  type SchemaBlockPlugin,
  type SchemaBlockRules
} from '../../interface';
import {
  getBeforeInsertFragmentPlugins,
  SlateBlock,
  type SlateMark,
  SlateObject
} from '../../../../interface';
import {getTextsInOneBlockFragment} from '../../utils';
import {isBlockOfType} from '../../../../utils';
import {type MCAnswerBlock, type MCAnswerData} from './MultipleChoice/interface';
import {blockIsQuestionAnswer, blockIsQuestionLabel, getAncestorQuestionOfBlock} from './utils';
import {insertNewAnswers} from './changes';

interface QuestionAnswerPluginOptions {
  genKey: () => string;
  preventCreate?: boolean;
  preventDelete?: boolean;
  allowedMarks?: SlateMark[];
}

export default class QuestionAnswer implements SchemaBlockPlugin, InsertFragmentPlugin, Plugin {
  public readonly block = SlateBlock.QUESTION_ANSWER;
  private readonly genKey: () => string;
  private readonly preventCreate?: boolean;
  private readonly preventDelete?: boolean;
  private readonly allowedMarks?: SlateMark[];

  constructor({genKey, preventCreate, preventDelete, allowedMarks}: QuestionAnswerPluginOptions) {
    this.genKey = genKey;
    this.preventCreate = preventCreate;
    this.preventDelete = preventDelete;
    this.allowedMarks = allowedMarks;
  }

  public blockRules = (): SchemaBlockRules => {
    const rules: Rules = {
      nodes: [
        {
          match: [
            {
              type: SlateBlock.DEFAULT,
              object: SlateObject.BLOCK
            }
          ],
          max: 1
        }
      ],
      data: {
        id: (v: unknown) => typeof v === 'string' && v.length > 0,
        checked: (v?: boolean) => v === true || v === undefined
      }
    };
    const normalizer: Normalizer = {
      predicate: ({node}: SlateError) => isBlockOfType(node, SlateBlock.QUESTION_ANSWER),
      reasons: {
        child_object_invalid: (change: Editor, {child}: SlateError) => {
          change.wrapNodeByKey(child!.key, {
            type: SlateBlock.DEFAULT,
            object: SlateObject.BLOCK
          } as NodeProperties);
          return true;
        },
        child_max_invalid: (change: Editor, {child, node}: SlateError) => {
          if (isBlockOfType(child as Block, SlateBlock.DEFAULT)) {
            change.splitNodeByKey(node.key, 1);
            const createdAnswer = change.value.document.getNextSibling(node.key) as MCAnswerBlock;
            change.setNodeByKey(createdAnswer.key, {data: {id: this.genKey()}});
            return true;
          }
          return;
        },
        node_data_invalid: (change: Editor, {node}: SlateError) => {
          const data: MCAnswerData = {id: this.genKey()};
          change.setNodeByKey(node.key, {data});
          return true;
        }
      }
    };
    if (this.allowedMarks) {
      rules.marks = this.allowedMarks.map(m => ({type: m}));
      normalizer.reasons.node_mark_invalid = (change: Editor, {node, mark}: SlateError) => {
        node.getTexts().forEach((t: Text) => change.removeMark(mark));
        return true;
      };
    }
    const blockRules: SchemaBlockRules = {
      type: this.block,
      rules,
      normalizer
    };
    return blockRules;
  };

  public onQuery = (query: Query, editor: Editor, next: Next) => {
    if (query.type === getBeforeInsertFragmentPlugins) {
      const plugins = next() || [];
      plugins.unshift(this);
      return plugins;
    }
    return next();
  };

  public onKeyDown = (event: React.KeyboardEvent, change: ReactEditor & Editor, next: Next) => {
    const {value} = change;
    const {blocks, startBlock, selection, document} = value;
    if (!startBlock) {
      return next();
    }

    if (this.preventCreate && isShortcut(event, 'enter')) {
      return;
    }

    const selectionHasAnswerBlocks = blocks.find(block => blockIsQuestionAnswer(value, block!));
    const selectionHasQuestionLabelBlocks = blocks.find(block =>
      blockIsQuestionLabel(value, block!)
    );
    if (
      selectionHasAnswerBlocks &&
      selectionHasQuestionLabelBlocks &&
      !isShortcut(event, 'mod+c')
    ) {
      event.preventDefault();
      return;
    }

    // ignore backspace start of first answer and delete at end of last answer if selection is collapsed
    if (selection?.isExpanded || !blockIsQuestionAnswer(value, startBlock)) {
      return next();
    }

    const selectedAnswer = document.getParent(startBlock.key) as Block;
    const isFirstAnswerInQuestion = isBlockOfType(
      document.getPreviousSibling(selectedAnswer.key)!,
      SlateBlock.DEFAULT
    );

    if (
      isShortcut(event, 'backspace') &&
      value.selection?.start.isAtStartOfNode(startBlock) &&
      (isFirstAnswerInQuestion || this.preventDelete)
    ) {
      return;
    }

    const isLastAnswerInQuestion = !document.getNextSibling(selectedAnswer.key);
    if (
      isShortcut(event, 'delete') &&
      selection?.end.isAtEndOfNode(startBlock) &&
      (isLastAnswerInQuestion || this.preventDelete)
    ) {
      return;
    }

    return next();
  };

  public onBeforeInsertFragment = (
    event: React.ClipboardEvent,
    tmpChange: Editor,
    change: Editor
  ) => {
    const {startBlock, endBlock, selection} = change.value;
    if (!startBlock || !endBlock) {
      return;
    }
    const allCopiedNodesAreAnswers = !tmpChange.value.document.nodes.find(
      (node: Block) => !isBlockOfType(node, SlateBlock.QUESTION_ANSWER)
    );
    const hasMoreThenOneBlock = tmpChange.value.document.nodes.size > 1;

    if (allCopiedNodesAreAnswers && hasMoreThenOneBlock) {
      const {document} = change.value;
      const startAndEndAreAnswers =
        blockIsQuestionAnswer(change.value, startBlock) &&
        blockIsQuestionAnswer(change.value, endBlock);
      const startAndEndInSingleQuestion =
        getAncestorQuestionOfBlock(document, startBlock).key ===
        getAncestorQuestionOfBlock(document, endBlock).key;
      // if all blocks in copy buffer are ANSWERS blocks, and current selection covers only answers of single question,
      // add copied answers to answers of this question
      if (startAndEndAreAnswers && startAndEndInSingleQuestion) {
        if (selection?.isExpanded) {
          change.delete();
        }
        change.withoutNormalizing(() => {
          change.command(insertNewAnswers, tmpChange, this.genKey);
        });

        return true;
      }
    }
    return getTextsInOneBlockFragment(tmpChange);
  };
}
