import React from 'react';

import {type StyledListStyles} from 'store/exercise/player/widgets/List/StyledList/interface';

import './Star.scss';

interface Props {
  styleName: StyledListStyles;
}
export const StarSVG: React.FC<Props> = ({styleName}) => {
  return (
    <svg
      className={`star-svg ${styleName}`}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="star-svg__big-star"
        d="M18.2095 1.13883C18.9143 -0.379611 21.0857 -0.379608 21.7905 1.13883L26.2134 10.6686C26.5006 11.2874 27.0907 11.7135 27.7717 11.7938L38.258 13.0294C39.9289 13.2263 40.5999 15.2788 39.3646 16.4141L31.6118 23.5395C31.1083 24.0022 30.8829 24.6916 31.0166 25.3601L33.0745 35.6535C33.4024 37.2936 31.6457 38.5621 30.1775 37.7453L20.9631 32.6193C20.3647 32.2864 19.6353 32.2864 19.0369 32.6193L9.8225 37.7453C8.35431 38.5621 6.59756 37.2936 6.92547 35.6535L8.98345 25.3601C9.11709 24.6916 8.89169 24.0022 8.38823 23.5395L0.635428 16.4141C-0.599878 15.2788 0.0711467 13.2263 1.742 13.0294L12.2283 11.7938C12.9093 11.7135 13.4994 11.2874 13.7866 10.6686L18.2095 1.13883Z"
      />
      <g filter="url(#filter0_d_3356_8655)">
        <path
          className="star-svg__small-star"
          d="M19.1048 11.5394C19.4571 10.8202 20.5429 10.8202 20.8952 11.5394L23.1067 16.0535C23.2503 16.3467 23.5454 16.5485 23.8858 16.5865L29.129 17.1718C29.9644 17.2651 30.2999 18.2373 29.6823 18.7751L25.8059 22.1503C25.5542 22.3694 25.4415 22.696 25.5083 23.0127L26.5373 27.8885C26.7012 28.6654 25.8228 29.2663 25.0887 28.8794L20.4815 26.4512C20.1824 26.2936 19.8176 26.2936 19.5185 26.4512L14.9113 28.8794C14.1772 29.2663 13.2988 28.6654 13.4627 27.8885L14.4917 23.0127C14.5585 22.696 14.4458 22.3694 14.1941 22.1503L10.3177 18.7751C9.70006 18.2373 10.0356 17.2651 10.871 17.1718L16.1142 16.5865C16.4546 16.5485 16.7497 16.3467 16.8933 16.0535L19.1048 11.5394Z"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3356_8655"
          x="2"
          y="6"
          width="36"
          height="34"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" />
          <feBlend
            mode="color-burn"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3356_8655"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3356_8655"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
