import React, {type FC, useCallback} from 'react';
import {FormattedMessage} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';

import Icon from 'components/Icon';
import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';
import './UnknownWidget.scss';

export const UnknownWidget: FC<WidgetTypeComponentProps> = () => {
  return (
    <div className="unknown">
      <Icon name="exclamation-triangle" />
      <span className="header">
        <FormattedMessage id="Outdated.UnableToDisplayContent" />
      </span>
      <span className="description">
        <FormattedMessage id="Outdated.Description" />
      </span>
      <Button bsSize="sm" onClick={useCallback(() => window.location.reload(), [])}>
        <FormattedMessage id="Common.Refresh" />
      </Button>
    </div>
  );
};
