import {type Editor, type Next, type Node} from '@englex/slate';
import {type Editor as ReactEditor, type RenderBlockProps} from '@englex/slate-react';
import classNames from 'classnames';

import {genKey, isBlockOfType, isInline} from 'components/Slate/utils';
import isShortcut from 'helpers/shortcut';
import {type ListItemBlock, ListType, SlateBlock} from 'components/Slate/interface';
import DecimalRenderer from 'components/Slate/plugins/renderers/List/Decimal';
import {type StyledListStyles} from 'store/exercise/player/widgets/List/StyledList/interface';
import {WidgetType} from 'store/exercise/player/interface';

import {getListItemBlockOfBlock, getPreviousSiblingBlockInListItem} from '../utils';
import {buttonTitle} from '../../i18n';
import List from '../';
import {moveBlockFromListItem, toggleList} from '../changes';

import './StyledList.scss';

interface Props {
  pluginType?: WidgetType;
  styleName?: StyledListStyles;
  containerRef?: React.MutableRefObject<HTMLOListElement | null>;
}

export class StyledListPlugin extends List {
  public icon = 'list-numbers';
  public list = ListType.OL;
  public shortcut = 'mod+shift+8';
  public title = buttonTitle.OrderedListDecimal;
  public pluginType: WidgetType;
  public styleName?: StyledListStyles;
  public containerRef?: React.MutableRefObject<HTMLOListElement | null>;
  public plugins = [new DecimalRenderer()];

  constructor({
    pluginType = WidgetType.HORIZONTAL_STYLED_LIST,
    styleName,
    containerRef
  }: Props = {}) {
    super({shouldHide: () => pluginType === WidgetType.HORIZONTAL_STYLED_LIST});
    this.pluginType = pluginType;
    this.styleName = styleName;
    this.containerRef = containerRef;
  }

  public onKeyDown = (event: React.KeyboardEvent, change: Editor, next: Next) => {
    const {value} = change;
    const {startBlock, document, endBlock} = value;
    if (!startBlock || !endBlock) return next();
    const blockHasInline = !!startBlock.nodes.find((node?: Node) => !!node && isInline(node));
    const currentLi = getListItemBlockOfBlock(document, startBlock) as ListItemBlock;

    const previousSiblingBlock = getPreviousSiblingBlockInListItem(document, startBlock);
    const blockIsEmpty = !startBlock.text.length && !blockHasInline;
    const isLastItem = !document.getPreviousSibling(currentLi?.key);
    const isVerticalStyledList = this.pluginType === WidgetType.VERTICAL_STYLED_LIST;

    if (currentLi?.type === SlateBlock.LIST_ITEM) {
      if (isShortcut(event, 'shift+enter')) {
        change.insertText('\n');
        return;
      }

      if (isShortcut(event, 'enter')) {
        event.preventDefault();

        if (
          !previousSiblingBlock &&
          blockIsEmpty &&
          this.shouldUnwrapEmptyLiOnEnter &&
          this.pluginType === WidgetType.VERTICAL_STYLED_LIST
        ) {
          // if block is empty and is first block in li, call toggleList, which will unwrap current li
          change.command(toggleList, this.list, this.listStyleType);
        } else {
          change.splitBlock(2);
          change.setNodeByKey(currentLi.get('key'), {data: currentLi.data.concat({id: genKey()})});
        }
        return;
      }

      if (isShortcut(event, 'backspace')) {
        if (previousSiblingBlock || (isVerticalStyledList && isLastItem)) {
          return this.onBackspace(change, currentLi, startBlock, endBlock, next);
        }
      }

      if (isShortcut(event, 'delete')) {
        return this.onDelete(change, startBlock, endBlock, blockHasInline, next);
      }
    }

    const nextBlock = document.getNextBlock(startBlock.key);

    if (isShortcut(event, 'delete') && nextBlock) {
      // if next block is in list, and current block is not in the same list, we should unwrap next block
      if (this.shouldUnwrapNextBlock(value, nextBlock, startBlock)) {
        change.command(moveBlockFromListItem, nextBlock);
        if (!!getListItemBlockOfBlock(document, startBlock)) {
          change.deleteForward(1);
        } else {
          // fixes issue of reverting this operation when start block is not in list
          const newValue = change.value;
          if (newValue.startBlock) {
            const unwrappedBlock = newValue.document.getNextBlock(newValue.startBlock.key);
            change.mergeNodeByKey(unwrappedBlock!.key);
          }
        }
        return;
      }
    }

    return next();
  };

  public renderBlock = (
    {parent, node, attributes, children}: RenderBlockProps,
    editor: ReactEditor & Editor,
    next: Next
  ) => {
    if (!isBlockOfType(node, SlateBlock.LIST)) {
      return next();
    }

    return (
      <ol
        {...attributes}
        ref={this.containerRef}
        className={classNames(
          'styled-list-widget',
          this.pluginType === WidgetType.HORIZONTAL_STYLED_LIST ? 'horizontal' : 'vertical',
          this.styleName
        )}
      >
        {children}
      </ol>
    );
  };
}
