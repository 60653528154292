import React, {memo, useMemo} from 'react';
import {type Value} from '@englex/slate';
import {type Plugin} from '@englex/slate-react';

import LoadableImage from 'components/LoadableImage/LoadableImage';
import Undo from 'components/Slate/SlateEditor/plugins/button/History/Undo';
import Redo from 'components/Slate/SlateEditor/plugins/button/History/Redo';
import Bold from 'components/Slate/SlateEditor/plugins/button/Bold';
import Italic from 'components/Slate/SlateEditor/plugins/button/Italic';
import Underline from 'components/Slate/SlateEditor/plugins/button/Underline';
import StrikeThrough from 'components/Slate/SlateEditor/plugins/button/StrikeThrough';
import FontColorToolbar from 'components/Slate/SlateEditor/plugins/button/Color';
import CharSelector from 'components/Slate/SlateEditor/plugins/button/CharSelector';
import FormatPainter from 'components/Slate/SlateEditor/plugins/button/FormatPainter';
import ClearFormatting from 'components/Slate/SlateEditor/plugins/button/ClearFormatting';
import HighlightToolbar from 'components/Slate/SlateEditor/plugins/button/Highlight';
import {SelectionPointer} from 'components/Slate/plugins/renderers/Pointer/SelectionPointer';
import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';
import {type DialogueProperties} from 'store/exercise/player/widgets/Dialogue/interface';
import {
  AvatarImage,
  AvatarTypes
} from 'routes/Library/XEditorPage/components/XEditor/xwidgets/XDialogue/assets/AvatarImage';
import SlatePlayer from 'components/Slate/SlatePlayer/SlatePlayer';

import './MessageCard.scss';

const slatePlugins: Plugin[] = [
  new Undo(),
  new Redo(),
  new Bold(),
  new Italic(),
  new Underline(),
  new StrikeThrough(),
  new FontColorToolbar(),
  new HighlightToolbar(),
  new CharSelector(),
  new FormatPainter(),
  new ClearFormatting()
];

interface Props {
  cardId: string;
  name: Value;
  message: Value;
  styleName: string;
  preview?: boolean;
  avatar?: number;
  getWidgetProps?: () => WidgetTypeComponentProps<DialogueProperties>;
}

export const MessageCard: React.FC<Props> = memo(
  ({cardId, avatar, name, message, styleName, preview, getWidgetProps}) => {
    const plugins = useMemo(() => {
      if (preview) {
        return [];
      }
      return [...slatePlugins, new SelectionPointer({id: cardId})];
    }, [cardId, preview]);

    const namePlugins = useMemo(() => {
      if (preview) {
        return [];
      }
      return [new SelectionPointer({id: cardId})];
    }, [cardId, preview]);

    return (
      <div className={`message-card ${styleName}`}>
        <div className="avatar">
          {avatar ? (
            <LoadableImage imageId={avatar} width="100%" height="100%" />
          ) : (
            <AvatarImage avatarType={AvatarTypes.DEFAULT} />
          )}
        </div>

        <div className="message-container">
          <div className="control-panel">
            <SlatePlayer
              value={name}
              plugins={namePlugins}
              className="user-name"
              getWidgetProps={getWidgetProps}
            />
          </div>
          <div className="message-text">
            <SlatePlayer
              value={message}
              plugins={plugins}
              className="message"
              getWidgetProps={getWidgetProps}
            />
          </div>
        </div>
      </div>
    );
  }
);
