import {Document, type Next} from '@englex/slate';
import {type Editor as ReactEditor, type Plugin, type RenderBlockProps} from '@englex/slate-react';
import React from 'react';

import QuestionAnswer from '../QuestionAnswer';
import {isBlockOfType} from '../../../../../utils';
import {SlateBlock, type SlateMark} from '../../../../../interface';
import {copyFragment} from '../../../utils';
import './MatchingAnswer.scss';

interface MatchingAnswerPluginOptions {
  genKey: () => string;
  allowedMarks?: SlateMark[];
}

class MatchingAnswer implements Plugin {
  public plugins: Plugin[];

  constructor({genKey, allowedMarks}: MatchingAnswerPluginOptions) {
    this.plugins = [new QuestionAnswer({genKey, allowedMarks})];
  }

  public onCopy = (e: React.ClipboardEvent, {value}: ReactEditor) => {
    const {document, selection} = value;

    if (!selection) return;

    const {anchor, focus} = selection;
    const range = document.createRange({anchor, focus});
    const fragment = Document.create(document.getFragmentAtRange(range).getBlocks());
    copyFragment(e, fragment);
    return;
  };

  public renderBlock = (
    {node, attributes, children, parent}: RenderBlockProps,
    editor: ReactEditor,
    next: Next
  ) => {
    if (!isBlockOfType(node, SlateBlock.QUESTION_ANSWER)) {
      return next();
    }

    return (
      <div {...attributes} className="matching-answer">
        {children}
      </div>
    );
  };
}

export default MatchingAnswer;
