import React from 'react';
import {
  type Editor as ReactEditor,
  getEventTransfer,
  type RenderBlockProps,
  type Plugin
} from '@englex/slate-react';
import {Block, type Editor, type Next, type SlateError} from '@englex/slate';

import RenderListItem from 'components/Slate/plugins/renderers/List/ListItem';
import {StyledListStyles} from 'store/exercise/player/widgets/List/StyledList/interface';
import {WidgetType} from 'store/exercise/player/interface';

import {SlateBlock, SlateObject} from '../../../../../interface';
import {genKey, isBlockOfType, isListBlock, isListItemBlock} from '../../../../../utils';
import {StyledListFactory} from './components/StyledListFactory/StyledListFactory';
import {type SchemaBlockPlugin, type SchemaBlockRules} from '../../../interface';

interface Props {
  pluginType?: WidgetType;
  isPlayer?: boolean;
  styleName?: StyledListStyles;
  activeItemId?: React.MutableRefObject<string | undefined>;
  withNumbering?: boolean;
  twoColumns?: boolean;
  selectItem?: (itemId: string) => void;
}

class StyledListItem implements Plugin, SchemaBlockPlugin {
  public plugins = [new RenderListItem()];
  public block = SlateBlock.LIST_ITEM;
  public pluginType: WidgetType;
  public isPlayer: boolean;
  public styleName: StyledListStyles;
  public activeItemId?: React.MutableRefObject<string | undefined>;
  public withNumbering: boolean;
  public twoColumns: boolean;
  public selectItem?: (itemId: string) => void;

  constructor({
    pluginType = WidgetType.HORIZONTAL_STYLED_LIST,
    isPlayer = false,
    styleName = StyledListStyles.BLUE,
    activeItemId,
    withNumbering = false,
    twoColumns,
    selectItem
  }: Props = {}) {
    this.pluginType = pluginType;
    this.isPlayer = isPlayer;
    this.styleName = styleName;
    this.selectItem = selectItem;
    this.activeItemId = activeItemId;
    this.withNumbering = !!withNumbering;
    this.twoColumns = !!twoColumns;
  }

  public blockRules = (): SchemaBlockRules => {
    return {
      type: this.block,
      rules: {
        data: {
          id: (id?: string) => !!id,
          style: () => true
        },
        nodes: [
          {
            match: [{type: SlateBlock.DEFAULT}],
            min: 1,
            max: 1
          }
        ],

        parent: {type: SlateBlock.LIST}
      },
      normalizer: {
        predicate: ({node}: SlateError) => !!node && isListItemBlock(node),
        reasons: {
          node_data_invalid: (change: Editor, {node}: SlateError) => {
            if (!node.data.id) {
              change.setNodeByKey(node.key, {data: (node as Block).data.concat({id: genKey()})});
              return true;
            }
            return;
          },
          parent_type_invalid: (change: Editor, {node}: SlateError) => {
            change.unwrapBlockByKey(node.key, {
              type: this.block
            });
            return true;
          },
          child_min_invalid: (change: Editor, error: SlateError) => {
            change.removeNodeByKey(error.node.key);
            return true;
          },
          child_type_invalid: (change: Editor, {child, node}: SlateError) => {
            if (!child) {
              return;
            }
            if (child.object === SlateObject.TEXT) {
              change.removeNodeByKey(node.key);
              return true;
            }
            if (child.object !== SlateObject.TEXT) {
              change.unwrapNodeByKey(child.key);
              return true;
            }
            if (node.object === SlateBlock.LIST_ITEM) {
              return true;
            }
            return;
          },
          child_unknown: (change: Editor, {child}: SlateError) => {
            if (isListBlock(child)) {
              if (isListBlock(change.value.document.getPreviousSibling(child.key))) {
                change.mergeNodeByKey(child.key);
                return true;
              }
            }
            return;
          },
          child_max_invalid: (change: Editor, {child, node}: SlateError) => {
            if (Block.isBlock(child) && isBlockOfType(child, SlateBlock.DEFAULT)) {
              change.splitNodeByKey(node.key, 1);
              change.setNodeByKey(node.key, {data: (node as Block).data.concat({id: genKey()})});
              return true;
            }
            return;
          }
        }
      }
    };
  };

  public renderBlock = (
    {parent, node, attributes, children, isSelected}: RenderBlockProps,
    editor: ReactEditor & Editor,
    next: Next
  ) => {
    if (!isBlockOfType(node, SlateBlock.LIST_ITEM)) {
      return next();
    }

    const onPaste = (e: React.ClipboardEvent<HTMLLIElement | HTMLDivElement>) => {
      const {html} = getEventTransfer(e.nativeEvent);

      if (!html) {
        e.preventDefault();
        e.stopPropagation();

        editor.insertText(e.clipboardData.getData('text/plain').trim());
      }
    };

    if (!this.isPlayer) {
      return (
        <li key={node.data.get('id')} {...attributes} onPaste={onPaste}>
          {children}
        </li>
      );
    }
    const onClick = () => {
      this.selectItem && this.selectItem(node.data.get('id'));
    };

    return (
      <StyledListFactory
        index={node.data.get('index')}
        key={node.data.get('id')}
        attributes={attributes}
        id={node.data.get('id')}
        styleName={this.styleName}
        listType={this.pluginType}
        children={children}
        editor={editor}
        isActive={!!node.data.get('isActive')}
        withNumbering={this.withNumbering}
        twoColumns={this.twoColumns}
        onClick={onClick}
        onPaste={onPaste}
      />
    );
  };
}

export default StyledListItem;
