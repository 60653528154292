import React, {PureComponent} from 'react';
import {Portal} from 'react-portal';
import {connect} from 'react-redux';

import {type AppState, type Role} from 'store/interface';

interface Defaults {
  containerId: string;
  validRoles: Role[];
}

interface OwnProps extends Defaults {
  getPlayerNode(): HTMLDivElement | null;
  preview?: boolean;
  role?: Role;
  placeholder?: React.ReactNode;
  isActive?: boolean;
}

interface StateProps {
  clientHeight?: number;
  playerTop?: number;
  scrollTop?: number;
}

interface Props extends OwnProps, StateProps {
  children: (inPortal?: boolean) => React.ReactNode; // makes child not optional
}

interface State {
  shouldPortal: boolean;
  audioPlayerHeight: number;
}

class PlayerPortalWrapper extends PureComponent<
  OwnProps & {children(inPortal?: boolean): React.ReactNode}
> {
  public static defaultProps: Defaults = {
    containerId: 'x-player-audio-player-portal',
    validRoles: ['student']
  };
  private get validRole() {
    const {role, validRoles} = this.props;
    return role ? validRoles.includes(role) : true;
  }

  public render() {
    const {children, isActive} = this.props;

    const active = isActive && this.validRole;

    return (
      <ConnectedCalculator {...this.props} isActive={active}>
        {children}
      </ConnectedCalculator>
    );
  }
}

class Calculator extends PureComponent<Props, State> {
  public state: State = {shouldPortal: false, audioPlayerHeight: 0};
  private portal?: HTMLElement | null;

  public componentDidMount(): void {
    this.portal = document.getElementById(this.props.containerId);

    if (this.props.isActive) {
      this.setupPortalDecision();
    }
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if (
      this.props.isActive &&
      (this.props.scrollTop !== prevProps.scrollTop ||
        this.state.audioPlayerHeight !== prevState.audioPlayerHeight)
    ) {
      this.setupPortalDecision();
    }
  }

  public render() {
    const {isActive, children, placeholder} = this.props;
    const {shouldPortal} = this.state;

    if (isActive) {
      return (
        <div className="audio-player-portal-wrapper">
          {shouldPortal && placeholder ? placeholder : children()}
          {shouldPortal && (
            <Portal node={this.portal}>
              <div>{children(shouldPortal)}</div>
            </Portal>
          )}
        </div>
      );
    }

    return <div className="audio-player-portal-wrapper">{children()}</div>;
  }

  private setupPortalDecision = () => {
    const {clientHeight, playerTop, getPlayerNode} = this.props;
    const {shouldPortal} = this.state;
    const playerNode = getPlayerNode();
    if (playerTop !== undefined && clientHeight !== undefined && playerNode && this.portal) {
      const {top: nodeTop, bottom: nodeBottom} = playerNode.getBoundingClientRect();

      const playerIsNotFullyVisible = nodeTop < playerTop || nodeBottom > playerTop + clientHeight;

      if (!shouldPortal && playerIsNotFullyVisible) this.setState({shouldPortal: true});
      else if (shouldPortal && !playerIsNotFullyVisible) this.setState({shouldPortal: false});
    }
  };
}

const mapStateToProps = (state: AppState): StateProps => {
  let [playerTop, scrollTop, clientHeight]: Array<number | undefined> = [
    undefined,
    undefined,
    undefined
  ];

  if (state.xplayer?.layout) {
    const layout = state.xplayer.layout;
    [playerTop, scrollTop, clientHeight] = [
      layout.playerTop,
      layout.scrollTop,
      layout.clientHeight
    ];
  }

  return {playerTop, scrollTop, clientHeight};
};

const ConnectedCalculator = connect(mapStateToProps)(Calculator);

export default PlayerPortalWrapper;
