import React from 'react';
import classNames from 'classnames';
import Overlay from 'react-bootstrap/lib/Overlay';
import Popover from 'react-bootstrap/lib/Popover';

import Icon from '../../../../../Icon';
import {type Props as ContainerProps} from '../../../containers/Exercise/VolumeControl';
import VolumeSlider from '../VolumeSlider/VolumeSlider';
import {dropdownDropupCheckCreator} from '../../../../../../helpers/dropdownDropupCheck';
import './VolumeControl.scss';

interface State {
  popoverIsOpen: boolean;
  placement: 'top' | 'bottom';
}

interface Props extends ContainerProps {
  disabled?: boolean;
  inPortal?: boolean;
  isFullscreen?: boolean;
  onPopupCallback?: (popupIsOpen?: boolean) => void;
  placement?: 'top' | 'bottom';
  parentContainerClass?: string;
}

export default class VolumeControl extends React.PureComponent<Props, State> {
  public static popoverHeight = 112;
  public state: State = {popoverIsOpen: false, placement: this.props.placement || 'bottom'};

  private popoverPlacementCheck = dropdownDropupCheckCreator(
    (dropup: boolean) => this.setState({placement: dropup ? 'top' : 'bottom'}),
    VolumeControl.popoverHeight,
    () => document.getElementsByClassName(this.props.parentContainerClass!).item(0) || document.body
  );

  private get icon() {
    const {volume} = this.props;
    const suffix = volume === undefined || volume === 1 ? 'up' : volume === 0 ? 'off' : 'down';
    return `volume-${suffix}`;
  }

  public render() {
    const [{changeVolume, disabled, inPortal, isFullscreen, volume}, {popoverIsOpen}] = [
      this.props,
      this.state
    ];
    const btnClasses = classNames('btn-volume icon-button btn btn-default', {
      active: popoverIsOpen
    });
    return (
      <div className="popover-overlay">
        <button
          className={btnClasses}
          disabled={disabled}
          onClick={this.onClick}
          onKeyDown={this.onKeyDown}
        >
          <Icon name={this.icon} />
        </button>
        <Overlay
          rootClose={true}
          placement={this.state.placement}
          container={inPortal ? undefined : this}
          animation={false}
          show={popoverIsOpen}
          onHide={this.hide}
          target={this}
        >
          <Popover id="volume-popover" className="volume-popover" arrowOffsetLeft="50%">
            <VolumeSlider
              changeVolume={changeVolume}
              isFullscreen={isFullscreen}
              setValue={this.setValue}
              volume={volume}
            />
          </Popover>
        </Overlay>
      </div>
    );
  }

  private onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const {disabled, parentContainerClass, placement} = this.props;
    if (!disabled) {
      if (!placement && parentContainerClass) this.popoverPlacementCheck(e);
      this.setState(({popoverIsOpen}) => ({popoverIsOpen: !popoverIsOpen}), this.onPopupCallback);
    }
  };

  private onPopupCallback = () => {
    const [{onPopupCallback}, {popoverIsOpen}] = [this.props, this.state];
    onPopupCallback && onPopupCallback(popoverIsOpen);
  };

  private onKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    const {volume} = this.props;
    if (e.key === 'ArrowUp' && volume !== undefined) {
      e.preventDefault();
      this.open();
      this.setValue(volume + 0.05);
    }
    if (e.key === 'ArrowDown' && volume !== undefined) {
      e.preventDefault();
      this.open();
      this.setValue(volume - 0.05);
    }
    if (e.key === 'Tab') this.hide();
  };

  private open = () => {
    if (!this.state.popoverIsOpen) this.setState({popoverIsOpen: true}, this.onPopupCallback);
  };

  private hide = () => {
    if (this.state.popoverIsOpen) this.setState({popoverIsOpen: false}, this.onPopupCallback);
  };

  private setValue = (value: number) => {
    this.props.changeVolume(value > 1 ? 1 : value < 0 ? 0 : value);
  };
}
